import React, {useContext, useState} from 'react';
import AdvancedTable from "../../../components/AdvancedTable";
import {fetchAdministratorsPage} from "../../../utils/api";
import {AppContext} from "../../../App";

const fields = [
    {key: "name", name: "Name"},
    {key: "surname", name: "Surname"},
    {key: "email", name: "Email address"},
]

export default function AdminsScreen({location}) {

    const [state,] = useContext(AppContext)
    const [args] = useState({apiKey: state.user.token});

    return (
        <AdvancedTable fields={fields}
                       requestArgs={args}
                       location={location}
                       fetchingPageFunction={fetchAdministratorsPage}
                       jwt={state.user}/>
    );
}
