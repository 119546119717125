import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Card, CardContent, TextField, Typography} from "@material-ui/core";
import KanbanItem from "../../../components/KanbanItem";
import Divider from "@material-ui/core/Divider";
import {Autocomplete} from "@material-ui/lab";
import {fetchStations} from "../../../utils/api";
import {AppContext} from "../../../App";
import {useQueryParams} from "../../../hooks/useQueryParams";


export default function KStationContent({station, modelsCount, semiAssCount, kanbans, productionKanbans, onResume}) {

    const [dispatch] = useContext(AppContext);

    const [stations, setStations] = useState([])

    const queryParams = useQueryParams()

    const selectedStationId = queryParams.qp.st
    const selectedStation = stations.find(s => s.id.toString() === selectedStationId) || null
    const isWarehouse = station && station.type === "WAREHOUSE"

    const filteredKanbans = kanbans.filter((k) => selectedStation == null || k.destinationId === selectedStation.id)

    const handleStationChange = (value) => {
        if (value)
            queryParams.setParams({
                st:value.id
            })
        else
            queryParams.popParam("st")

    }

    useEffect(() => {
        if (station && station.type === "WAREHOUSE")
            fetchStations()
                .then(result => {
                    setStations(result.payload)
                })
                .catch(e => dispatch({
                    type: "SHOW_ALERT",
                    payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                }))
    }, [dispatch, station])

    return (
        <React.Fragment>

            {isWarehouse && <Grid item xs={12}>
                <Autocomplete
                    fullWidth
                    options={stations}
                    value={selectedStation}
                    onChange={((event, value) => handleStationChange(value))}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) =>
                        <TextField
                            {...params} label="Focus station"
                            variant="outlined"/>}
                />
            </Grid>}

            {station && station.type !== "WAREHOUSE" &&
            <Grid item xs={12}>
                <Typography variant={"h6"}>
                    Available parts
                </Typography>
            </Grid>}

            {station && station.type !== "WAREHOUSE" && modelsCount.map(model =>
                <Grid item xs={6} md={4} xl={1} key={model.code}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography variant={"subtitle1"}>
                                        {model.code}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={8}>
                                    Available
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    {model.count}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}

            {station && station.type !== "WAREHOUSE" && semiAssCount.length > 0 &&
            <Grid item xs={12}>
                <Typography variant={"h6"}>
                    Available assembly
                </Typography>
            </Grid>}

            {station && station.type !== "WAREHOUSE" && semiAssCount.map(semi =>
                <Grid item xs={6} md={4} xl={1} key={semi.name}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography variant={"subtitle1"}>
                                        {semi.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={8}>
                                    Available
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    {semi.count}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}


            <Grid item xs={12}>
                <Typography variant={"h6"}>
                    Kanbans
                </Typography>
            </Grid>


            {station && (station.type === "WAREHOUSE" ? filteredKanbans : productionKanbans).map(k =>
                <Grid item xs={12} md={6} lg={4} xl={3} key={k.id}>
                    <Card>
                        <CardContent>
                            <KanbanItem
                                onResume={onResume}
                                kanban={k}/>
                        </CardContent>
                    </Card>
                </Grid>
            )}

        </React.Fragment>
    );
}
