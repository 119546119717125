export const MANUAL = "manual"
export const AUTO = "auto"

let queue = ""
const listeners = []
let active = true

export const setActive = (val) => active = val

export const addBarcodeListener = (id, listener) => {
    listeners[id] = listener
};

export const removeBarcodeListener = (id) => {
    delete listeners[id]
};

export const throwManualRead = (code) => {
    Object.values(listeners).forEach(l => l(code, MANUAL))
};

const onCodeSelected = (code) =>{
    Object.values(listeners).forEach(l => l(code, AUTO))
}

window.onkeyup = function (evt) {
    if (!active)
        return

    const key = evt.key.toUpperCase()

    if (/^[A-Z0-9-]{1}$/.test(key))
        queue += key
    else if (key === "ENTER" && queue.length > 6) {
        const code = queue.substr(queue.length - 7)
        if (/^[A-Z]{3}-[A-Z0-9]{3}$/.test(code)) {
            onCodeSelected(code)
            queue = ""
        }
    }
}