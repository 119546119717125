const checkToken = jwt_obj => {
    const date = new Date();

    // mi accerto che non sia nullo
    if(jwt_obj == null)
        return false

    // controllo che non sia scaduto
    if(jwt_obj.hasOwnProperty("expire") && jwt_obj.expire<date.getTime()/1000) {
        return false
    }

    // se tutti i controlli passano
    return true

}

export default checkToken