import React, {useContext, useState} from 'react';
import {AppContext} from "../../../App";
import AdvancedTable from "../../../components/AdvancedTable";
import {createNewSession, fetchAdministratorSessionsPage} from "../../../utils/api";
import Grid from "@material-ui/core/Grid";
import AddIcon from '@material-ui/icons/Add';
import Button from "@material-ui/core/Button";
import SessionCreationDialog from "../../../dialogs/session/SessionCreationDialog";

const fields = [
    {key: "sessionType", name: "Type"},
    {key: "code", name: "Code", sorting: false},
    {key: "creationTimeFormatted", name: "Creation time", sort: "creationDate"},
]

export default function SessionsScreen({location}) {

    const [state, dispatch] = useContext(AppContext)

    const [dialogOpen, setDialogOpen] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);

    const [args] = useState({apiKey: state.user.token});

    const handleSessionKindSelected = (kind) => {
        setDialogOpen(false)
        createNewSession({
            apiKey: state.user.token,
            payload: {
                sessionType: kind
            }
        })
            .then(() => {
                setRefreshTable(true)
                dispatch({type: "SHOW_ALERT", payload: {message: "New session initialized"}})
            })
            .catch((e) => dispatch({type: "SHOW_ALERT", payload: {message: e.message, severity: "error"}}))
    }

    return (
        <Grid container justifyContent={"flex-end"}>
            <Grid item>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setDialogOpen(true)}
                    startIcon={<AddIcon/>}>
                    New session
                </Button>
            </Grid>
            <Grid item xs={12}>
                <AdvancedTable fields={fields}
                               location={location}
                               requestArgs={args}
                               mustRefresh={refreshTable}
                               onRefreshed={() => setRefreshTable(false)}
                               fetchingPageFunction={fetchAdministratorSessionsPage}/>
            </Grid>

            <SessionCreationDialog
                onSelected={handleSessionKindSelected}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}/>
        </Grid>
    );
}
