import React, {useCallback, useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {AppContext} from "../../../../App";
import {fetchSessionStationOrders, fetchStations, postAssignTag, postDeliverOrder} from "../../../../utils/api";
import {Card, CardContent, TextField, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {Autocomplete} from "@material-ui/lab";
import OrderItem from "../../../../components/OrderItem";
import Button from "@material-ui/core/Button";
import ScanTagDialog from "../../../../dialogs/ScanTagDialog";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {compareInt} from "../../../../utils/utils";
import WithdrawingDialog from "../../../../dialogs/WithdrawingDialog";
import {addBarcodeListener, removeBarcodeListener} from "../../../../utils/AutomaticBarcodeScan";
import {useQueryParams} from "../../../../hooks/useQueryParams";

export default function WarehouseScreen() {

    const [state, dispatch] = useContext(AppContext);

    const queryParams = useQueryParams()

    const stationId = queryParams.qp.st
    const warehouseId = queryParams.qp.id

    const history = useHistory()
    const [stations, setStations] = useState([]);
    const [orders, setOrders] = useState([]);

    const [assignQrTagOrder, setAssignQrTagOrder] = useState(null);
    const [showPartsOrder, setShowPartsOrder] = useState(null);

    const [lastScannedOrder, setLastScannedOrder] = useState(null);

    const station = stations.find(s => s.id.toString() === stationId) || null
    const warehouse = stations.find(s => s.id.toString() === warehouseId) || {}

    useEffect(() => {

        const fetchOrders = () => {
            fetchSessionStationOrders({stationId: warehouseId, sessionId: state.session.id})
                .then(result => {
                    setOrders(result.payload)
                })
                .catch(e => dispatch({
                    type: "SHOW_ALERT",
                    payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                }))
        }

        fetchStations()
            .then(result => {
                setStations(result.payload)
            })
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))

        fetchOrders()

        const interval = setInterval(() => {
            fetchOrders()
        }, 5000);

        return () => clearInterval(interval)
    }, [history, state.session, dispatch, warehouseId])

    const handleStationChange = (value) => {
        if (value)
            queryParams.setParams({
                st: value.id
            })
        else
            queryParams.popParam("st")
    }

    const handleOrderDelivered = (o) => {
        postDeliverOrder({id: o.id})
            .then(() => {
                fetchSessionStationOrders({stationId: warehouseId, sessionId: state.session.id})
                    .then(result => {
                        setOrders(result.payload)
                    })
                    .catch(e => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                    }))

                dispatch({
                    type: "SHOW_ALERT",
                    payload: {message: "Order delivered"}
                })
            })
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
        setLastScannedOrder(undefined)
        setShowPartsOrder(undefined)
    }

    const handleTagAssign = (code) => {

        postAssignTag({id: assignQrTagOrder.id, tag: code})
            .then((result) => {
                    setShowPartsOrder(result.payload)
                    fetchSessionStationOrders({stationId: warehouseId, sessionId: state.session.id})
                        .then(result => {
                            setOrders(result.payload)
                        })
                        .catch(e => dispatch({
                            type: "SHOW_ALERT",
                            payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                        }))
                }
            )
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
            .finally(() =>
                setAssignQrTagOrder(null))

    }

    document.title = warehouse ? warehouse.name : "Warehouse";

    const onAutoCodeScanned = useCallback((code) => {
        const order = orders.find(o => o.tagCode === code)

        console.log("here")

        if (!order)
            dispatch({
                type: "SHOW_ALERT",
                payload: {message: "The scanned order is not assigned to the selected station", severity: "error"}
            })
        else {
            dispatch({
                type: "SHOW_ALERT",
                payload: {message: "Order found"}
            })
            setLastScannedOrder(order)
        }
    }, [dispatch, orders])

    useEffect(() => {
        if (!assignQrTagOrder)
            addBarcodeListener("WarehouseScreen", onAutoCodeScanned)
        return () =>
            removeBarcodeListener("WarehouseScreen")
    }, [onAutoCodeScanned, assignQrTagOrder])

    const handleOnPartsClose = (evt, reason) => {
        if (["backdropClick", "escapeKeyDown"].includes(reason))
            return
        setShowPartsOrder(null)
    }

    return (
        <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item xs={12} md={6}>
                <Typography variant={"h4"}>
                    {warehouse.name}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Autocomplete
                    fullWidth
                    options={stations}
                    value={station}
                    onChange={((event, value) => handleStationChange(value))}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) =>
                        <TextField
                            {...params} label="Focus station"
                            variant="outlined"/>}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {orders.filter(o => station == null || o.destinationId === station.id)
                        .sort((a, b) => compareInt(a.id, b.id))
                        .map(o =>
                            <Grid item xs={12} md={6} lg={4} key={o.id}>
                                <Card variant={"outlined"}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <OrderItem order={o} detailed={station == null}/>
                                            </Grid>
                                            {o.type !== "TRANSPORT" && !o.tagCode && <Grid item xs={12}>
                                                <Button fullWidth
                                                        onClick={() => setAssignQrTagOrder(o)}
                                                        variant={"contained"}
                                                        color={"primary"}>
                                                    Start withdrawing
                                                </Button>
                                            </Grid>}
                                            {o.type !== "TRANSPORT" && o.tagCode && <Grid item xs={12}>
                                                <Button fullWidth
                                                        onClick={() => setShowPartsOrder(o)}
                                                        variant={"contained"} color={"primary"}>
                                                    Resume withdrawing
                                                </Button>
                                            </Grid>}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                </Grid>
            </Grid>

            <ScanTagDialog
                open={!!assignQrTagOrder}
                onClose={() => setAssignQrTagOrder(null)}
                onSelected={handleTagAssign}/>

            <Dialog fullWidth maxWidth={"md"} open={!!lastScannedOrder} onClose={() => setLastScannedOrder(null)}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {lastScannedOrder && <OrderItem order={lastScannedOrder} detailed={true}/>}
                        </Grid>
                        <Grid item xs={12}>
                            {lastScannedOrder && lastScannedOrder.type === "TRANSPORT" ?
                                <Button fullWidth variant={"contained"}
                                        onClick={() => {
                                            const order = lastScannedOrder
                                            setLastScannedOrder(null)
                                            handleOrderDelivered(order)
                                        }}
                                        color={"primary"}>
                                    delivered
                                </Button>
                                :
                                <Button fullWidth variant={"contained"}
                                        onClick={() => {
                                            const order = lastScannedOrder
                                            setLastScannedOrder(null)
                                            setShowPartsOrder(order)
                                        }}
                                        color={"primary"}>
                                    start withdrawing
                                </Button>}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>


            {showPartsOrder && <WithdrawingDialog
                open={!!showPartsOrder}
                onDelivered={() => handleOrderDelivered(showPartsOrder)}
                onClose={handleOnPartsClose}
                parts={showPartsOrder.orderParts}
                destinationName={showPartsOrder.destinationName}
            />}

        </Grid>
    );
}
