import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from "@material-ui/core/Grid";
import {AppBar, Button, Slide, Toolbar, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import OperationSlider from "../../components/OperationSlider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
    appBar: {
        position: 'relative',
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function KanbanProcessingDialog({
                                                   open,
                                                   onClose,
                                                   onProcessed = () => {
                                                   },
                                                   onFailed = () => {
                                                   },
                                                   onSuccess = () => {
                                                   },
                                                   kanban,
                                                   quality = false
                                               }) {

    const [operationIdx, setOperationIdx] = useState(0);
    const operations = !quality ? kanban.kanbanTag.operations : kanban.kanbanTag.qualityOperations
    const lastOperation = operations.length === operationIdx + 1

    const handleOnClose = (e, reason) => {
        if (["backdropClick", "escapeKeyDown"].includes(reason))
            return
        onClose()
    }

    const handleOnExited = () => {
        setOperationIdx(0)
    }

    const classes = useStyles();

    const handleOnProcessed = () => {
        onProcessed(kanban)
    }
    const handleOnFailed = () => {
        onFailed(kanban)
    }
    const handleOnSuccess = () => {
        onSuccess(kanban)
    }

    return (
        <Dialog fullScreen maxWidth={"md"} open={open} onClose={handleOnClose}
                TransitionComponent={Transition}
                TransitionProps={{
                    onExited: handleOnExited
                }}>
            <AppBar className={classes.appBar}>
                <Toolbar>

                    <IconButton disabled={true} edge="start" color="inherit" onClick={handleOnClose}>
                        <CloseIcon/>
                    </IconButton>

                    <Typography variant="h6">
                        {quality ? "Inspection menu" : "Production menu"}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Grid container spacing={5} justifyContent={"center"}>
                    <React.Fragment>
                        <Grid item xs={12} sm={10} md={10} lg={8} xl={6}>
                            <OperationSlider
                                value={operationIdx}
                                onChange={(v) => setOperationIdx(v)}
                                operations={operations}/>
                        </Grid>
                        <Grid item xs={12}/>
                        {!quality && <Grid item xs={12} lg={4}>
                            <Button disabled={!lastOperation} onClick={handleOnProcessed} color={"primary"} fullWidth
                                    variant={"contained"}>

                                Production done and ready to pick-up

                            </Button>
                        </Grid>
                        }
                        {quality && <React.Fragment>
                            <Grid item xs={6} lg={3}>
                                <Button style={{height: "100%"}} disabled={!lastOperation} onClick={handleOnFailed}
                                        color={"secondary"} fullWidth
                                        variant={"contained"}>

                                    Inspection failed

                                </Button>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <Button disabled={!lastOperation} onClick={handleOnSuccess} color={"primary"}
                                        fullWidth
                                        variant={"contained"}>

                                    Inspection success

                                </Button>
                            </Grid>
                        </React.Fragment>
                        }
                    </React.Fragment>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}