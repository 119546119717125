import {useHistory} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";

const parseSearch = (searchString) => {
    try {
        let search = searchString.substring(1);
        return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
            return key === "" ? value : decodeURIComponent(value)
        })
    } catch (error) {
        return {}
    }
}

export function useQueryParams() {
    const history = useHistory()

    const setParams = useCallback((params) => {
        const currentParams = parseSearch(history.location.search)
        const newParams = Object.assign(currentParams, params);
        const search = new URLSearchParams(newParams).toString()

        history.push({
            search: `?${search}`
        })
    }, [history])

    const popParam = useCallback((key) => {
        const currentParams = parseSearch(history.location.search)
        delete currentParams[key]

        const search = new URLSearchParams(currentParams).toString()

        history.push({
            search: `?${search}`
        })
    }, [history])

    const [hook, setHook] = useState({
        qp: parseSearch(history.location.search),
        setParams: setParams,
        popParam: popParam
    })

    useEffect(() => {
        setHook({
            qp: parseSearch(history.location.search),
            setParams: setParams,
            popParam: popParam
        })
    }, [history.location.search, setParams, popParam])

    return hook
}