import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Card, CardContent, Typography} from "@material-ui/core";
import KanbanItem from "../../../components/KanbanItem";


export default function KQualityContent({station, kanbans, onResume}) {

    return (
        <React.Fragment>

            <Grid item xs={12}>
                <Typography variant={"h6"}>
                    Kanbans
                </Typography>
            </Grid>


            {station && kanbans.map(k =>
                <Grid item xs={12} md={6} lg={4} xl={3} key={k.id}>
                    <Card>
                        <CardContent>
                            <KanbanItem
                                onResume={onResume}
                                kanban={k}/>
                        </CardContent>
                    </Card>
                </Grid>
            )}

        </React.Fragment>)
}
