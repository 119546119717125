import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default function OperationSlider({
                                            operations,
                                            keyboardSlide = false,
                                            value,
                                            onChange = () => {
                                            }
                                        }) {

    const handlePreviousOperation = () => {
        if (value > 0)
            onChange(value - 1)
    }

    const handleNextOperation = () => {
        if (!lastOperation)
            onChange(value + 1)
    }

    const lastOperation = operations.length === value + 1

    document.onkeyup = function (e) {
        if (keyboardSlide) {
            if ((e.key === "ArrowRight" || e.key === "Enter" || e.key === " "))
                handleNextOperation()
            else if (e.key === "ArrowLeft")
                handlePreviousOperation()
        }
    }

    return (
        <Grid container spacing={4} justifyContent={"space-between"}>
            <Grid item xs={12} style={{textAlign: "center"}}>
                <Typography>
                    Current operation
                </Typography>
            </Grid>
            <Grid item xs={3} md={2} lg={1}>
                <Button
                    fullWidth
                    onClick={handlePreviousOperation}
                    disabled={value === 0}
                    variant={"contained"} color={"primary"} style={{height: "100%"}}>
                    <ArrowBackIosIcon/>
                </Button>
            </Grid>
            <Grid item xs={5} style={{textAlign: "center", fontSize: "4vw"}}>
                {operations.length > 0 && operations[value].code}
            </Grid>
            <Grid item xs={3} md={2} lg={1}>
                <Button
                    fullWidth
                    onClick={handleNextOperation}
                    disabled={lastOperation}
                    variant={"contained"} color={"primary"} style={{height: "100%"}}>
                    <ArrowForwardIosIcon/>
                </Button>
            </Grid>
        </Grid>
    )
}
