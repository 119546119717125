import React, {useContext, useState} from "react";
import {AppContext} from "../../../App";
import PaginatedTable from "../../../components/AdvancedTable";
import {fetchTagsScreen} from "../../../utils/api";

const fields = [
    {key: "code", name: "Code"},
    {key: "type", name: "Type"},
    {key: "stationName", name: "Station name"},
    {key: "modelCode", name: "Model code"},
]

export default function TagsScreen({location}) {

    const [state,] = useContext(AppContext)
    const [args] = useState({apiKey: state.user.token});

    return (
        <PaginatedTable fields={fields}
                        requestArgs={args}
                        location={location}
                        defaultSort={"code"}
                        fetchingPageFunction={fetchTagsScreen}
                        jwt={state.user}/>
    );
}