import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Box, Card, CardContent, CardHeader, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const OrderDetail = ({order}) => {


    return (
        <Grid item xs={12} md={4} lg={3}>
            <Card variant={"outlined"}>
                <CardHeader subheader={order.type}
                            action={
                                <Box m={1}>{order.compleated ? <AssignmentTurnedInIcon/> : <HourglassEmptyIcon/>}</Box>
                            }/>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            Status
                        </Grid>
                        <Grid item xs={6}>
                            {order.compleated ? "Done" : "Pending"}
                        </Grid>
                        <Grid item xs={6}>
                            Assignee
                        </Grid>
                        <Grid item xs={6}>
                            {order.assigneeName}
                        </Grid>
                        {order.sourceStationName && <Grid item xs={6}>
                            Source
                        </Grid>}
                        {order.sourceStationName && <Grid item xs={6}>
                            {order.sourceStationName}
                        </Grid>}
                        <Grid item xs={6}>
                            Destination
                        </Grid>
                        <Grid item xs={6}>
                            {order.destinationName}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default function WarehouseDetail({
                                            station,
                                            orders
                                        }) {

    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={"h5"}>
                        {station.name}
                    </Typography>
                </Grid>
                {orders.map(order => <OrderDetail key={order.id} order={order}/>)}
            </Grid>
        </Grid>
    );
}
