import React, {useContext, useEffect, useState} from 'react';
import {Route, useLocation} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import {Alert} from "@material-ui/lab";
import {AppContext} from "../../App";
import {AppBar, FormControlLabel, Switch, Toolbar, Typography} from "@material-ui/core";
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ScanTagDialog from "../../dialogs/ScanTagDialog";
import {setActive, throwManualRead} from "../../utils/AutomaticBarcodeScan";

function FagitLayout({children}) {
    const [state, dispatch] = useContext(AppContext);

    const location = useLocation();
    const enableToggle = location.pathname.includes("kanban") ||  location.pathname.includes("ato")

    const [showScanTag, setShowScanTag] = useState(false);

    const useStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            marginTop: 60
        },
        appBar: {
            backgroundColor: theme.palette.appBar
        },
        content: {
            padding: theme.spacing(3),
            flexGrow: 1,
        },
    }));

    const classes = useStyles();

    const handleToggleDark = (c) => () => {
        dispatch({
            type: "TOGGLE_THEME",
            payload: {darkTheme: c}
        })
    }

    const handleAutoScanChange = () => {
        dispatch({
            type: "TOGGLE_AUTOSCAN"
        })
    }

    const handleGenericScan = (scanned) => {
        setShowScanTag(false)
        throwManualRead(scanned)
    }

    useEffect(() => {
        setActive(state.autoScan)
        return () => setActive(false)
    }, [state.autoScan])

    return (
        <div className={classes.root}>
            <CssBaseline/>

            <ScanTagDialog
                open={showScanTag}
                onClose={() => setShowScanTag(false)}
                onSelected={handleGenericScan}/>

            {/*<CodeScanner onCodeSelected={handleCodeScanned}/>*/}

            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" style={{flexGrow: 1}}>
                        FaDeLaDiGT
                    </Typography>

                    {!state.autoScan && enableToggle && <IconButton
                        onClick={() => setShowScanTag(true)}
                        color="inherit"
                    >
                        <CropFreeIcon/>
                    </IconButton>}

                    {enableToggle && <FormControlLabel
                        control={<Switch
                            size="small"
                            color="primary"
                            checked={!!state.autoScan}
                            onChange={handleAutoScanChange}/>}
                        labelPlacement={"bottom"}
                        label="Auto scan"
                    />}
                    <IconButton
                        onClick={handleToggleDark(!state.darkTheme)}
                        color="inherit"
                    >
                        {state.darkTheme ? <Brightness7Icon/> : <Brightness4Icon/>}
                    </IconButton>

                </Toolbar>
            </AppBar>

            <main className={classes.content}>
                {children}
            </main>

            <Snackbar open={!!state.alert && state.alert.display}
                      autoHideDuration={state.alert ? state.alert.autoHideDuration : 2000}
                      onClose={(e, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }

                          dispatch({
                              type: "HIDE_ALERT"
                          })
                      }}

                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                      }}>
                {state.alert && <Alert severity={state.alert ? state.alert.severity : ""}>
                    <Grid container style={{
                        margin: 0,
                        width: '100%',
                    }}>
                        <Grid item xs={state.alert.autoHideDuration === null ? 11 : 12}>
                            {state.alert ?
                                (Array.isArray(state.alert.message) ?
                                    <ul>
                                        {state.alert.message.map(txt => <li key={txt}>{txt}</li>)}
                                    </ul>
                                    : state.alert.message)
                                : ""}
                        </Grid>
                        {state.alert.autoHideDuration === null &&
                        <Grid item xs={1}>

                            <IconButton size="small"
                                        onClick={() => dispatch({
                                            type: "HIDE_ALERT"
                                        })}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>

                        </Grid>
                        }
                    </Grid>

                </Alert>}
            </Snackbar>
        </div>
    )
}

const FagitLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <FagitLayout>
                <Component {...matchProps}/>
            </FagitLayout>
        )}/>
    )
};

export default FagitLayoutRoute;