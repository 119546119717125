import React from 'react';
import {Switch} from "react-router-dom";
import {Router} from "@material-ui/icons";
import FagitLayoutRoute from "../../../layouts/fagit/FagitLayout";
import WarehouseScreen from "./warehouse/WarehouseScreen";
import StationScreen from "./station/StationScreen";
import MrpRouter from "./mrp/MrpRouter";

export default function AtoRouter() {

    return (
        <Switch>
            <FagitLayoutRoute exact path="/role/ato/warehouse" component={WarehouseScreen}/>
            <FagitLayoutRoute exact path="/role/ato/station" component={StationScreen}/>
            <Router path="/role/ato/mrp" component={MrpRouter}/>
        </Switch>
    );
}
