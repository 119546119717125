import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import ProductionOrderCreationDialog
    from "../../../../../dialogs/stationOrder/production/ProductionOrderCreationDialog";
import WithdrawalOrderCreationDialog
    from "../../../../../dialogs/stationOrder/withdrawal/WithdrawalOrderCreationDialog";
import {AppContext} from "../../../../../App";
import {
    createProductionOrder,
    createQualityOrder,
    createWithdrawalOrder,
    fetchCustomerOrderStationOrders
} from "../../../../../utils/api";
import StationDetail from "./StationDetail";
import WarehouseDetail from "./WarehouseDetail";

const extractWorkingStations = (stations) => {
    return stations.filter(station => station.type !== "WAREHOUSE")
}

const extractWarehouses = (stations) => {
    return stations.filter(station => station.type === "WAREHOUSE")
}


export default function CustomerOrderDetail({stations, customerOrder}) {

    const [, dispatch] = useContext(AppContext);

    const [createProductionOrderOpen, setCreateProductionOrderOpen] = useState()
    const [createWithdrawalOrderOpen, setCreateWithdrawalOrderOpen] = useState()
    const [createQualityOrderOpen, setCreateQualityOrderOpen] = useState()

    const [stationOrders, setStationOrders] = useState([])

    useEffect(() => {
        const fetch = () => {
            fetchCustomerOrderStationOrders({id: customerOrder.id})
                .then(result => setStationOrders(result.payload))
                .catch(e => dispatch({
                    type: "SHOW_ALERT",
                    payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                }))
        }

        fetch()
        const interval = setInterval(() => {
            fetch()
        }, 5000);

        return () => clearInterval(interval)
    }, [dispatch, customerOrder])

    const updateStationOrders = () => {
        fetchCustomerOrderStationOrders({id: customerOrder.id})
            .then(result => setStationOrders(result.payload))
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
    }

    const handleOnStationOrderBuilt = (type) => (payload) => {
        switch (type) {
            case "W":
                setCreateWithdrawalOrderOpen(null)
                createWithdrawalOrder({payload: payload})
                    .then(() => {
                        updateStationOrders()
                        dispatch({
                            type: "SHOW_ALERT",
                            payload: {message: "Withdrawal order submitted"}
                        })
                    })
                    .catch(e => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                    }))
                break
            case "P":
                setCreateProductionOrderOpen(null)
                createProductionOrder({payload: payload})
                    .then(() => {
                        updateStationOrders()
                        dispatch({
                            type: "SHOW_ALERT",
                            payload: {message: "Production order submitted"}
                        })
                    })
                    .catch(e => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                    }))
                break
            case "Q":
                setCreateQualityOrderOpen(null)
                createQualityOrder({payload: payload})
                    .then(() => {
                        updateStationOrders()
                        dispatch({
                            type: "SHOW_ALERT",
                            payload: {message: "Quality order submitted"}
                        })
                    })
                    .catch(e => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                    }))
                break
            default:
        }

    }

    return (
        <Grid container spacing={2} alignItems={"stretch"}>
            {customerOrder.completed &&
            <Grid item xs={12}>
                <Typography variant={"h5"}>
                    Completed
                </Typography>
            </Grid>}
            {customerOrder.completed &&
            <Grid item xs={12}>
                {customerOrder.inQuality} over {customerOrder.quantity} are been produced in quality.
            </Grid>}
            <Grid item xs={12}>
                <Typography variant={"h5"}>
                    Production stations
                </Typography>
            </Grid>
            {extractWorkingStations(stations).map(station =>
                <StationDetail
                    stations={stations}
                    key={station.id}
                    onCreateProductionOrder={() => setCreateProductionOrderOpen(station)}
                    onCreateWithdrawalOrder={() => setCreateWithdrawalOrderOpen(station)}
                    onCreateQualityOrder={() => setCreateQualityOrderOpen(station)}
                    station={station}
                    orders={stationOrders.filter(o => o.assigneeId === station.id || o.destinationId === station.id)}/>
            )}

            {extractWarehouses(stations).map(station =>
                <WarehouseDetail
                    key={station.id}
                    station={station}
                    orders={stationOrders.filter(o => o.assigneeId === station.id)}/>
            )}


            <ProductionOrderCreationDialog
                station={createProductionOrderOpen}
                onSelected={handleOnStationOrderBuilt("P")}
                open={!!createProductionOrderOpen}
                customerOrder={customerOrder}
                onClose={() => setCreateProductionOrderOpen(null)}/>


            <ProductionOrderCreationDialog
                station={createQualityOrderOpen}
                onSelected={handleOnStationOrderBuilt("Q")}
                open={!!createQualityOrderOpen}
                customerOrder={customerOrder}
                onClose={() => setCreateQualityOrderOpen(null)}/>


            <WithdrawalOrderCreationDialog
                station={createWithdrawalOrderOpen}
                warehouses={extractWarehouses(stations)}
                onSelected={handleOnStationOrderBuilt("W")}
                open={!!createWithdrawalOrderOpen}
                customerOrder={customerOrder}
                onClose={() => setCreateWithdrawalOrderOpen(null)}/>

        </Grid>
    );
}
