import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {fetchStationParts} from "../../../utils/api";
import {AppContext} from "../../../App";
import {Box, TextField} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Autocomplete} from "@material-ui/lab";

export default function WithdrawalOrderCreationDialog({
                                                          station,
                                                          customerOrder,
                                                          open,
                                                          onClose,
                                                          onSelected,
                                                          warehouses,
                                                          saving
                                                      }) {

    const [dispatch] = useContext(AppContext);

    const [suitableParts, setSuitableParts] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [warehouseError, setWarehouseError] = useState("");

    useEffect(() => {
        if (!station)
            return

        fetchStationParts({stationId: station.id})
            .then(r => {
                r.payload.forEach(p => p.quantity = 0)
                setSuitableParts(r.payload)
            })
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
    }, [dispatch, station])

    const handleOnClose = (evt, reason) => {
        if (reason === "backdropClick")
            return

        onClose()
    }

    const handleOnExited = () => {
        setSuitableParts([])
    }

    const handleQtaChange = (partId, qta) => {
        const parts = [...suitableParts];
        const part = parts.filter(p => p.id === partId)[0]
        part.quantity = qta
        setSuitableParts(parts)
    }

    const handleOnSave = () => {
        if (!warehouse) {
            setWarehouseError("A warehouse must be selected")
            return
        } else
            setWarehouseError("")

        const parts = suitableParts.filter(p => p.quantity !== 0).map(p => {
            return {...p}
        })
        parts.forEach(p => p.quantity *= customerOrder.quantity)

        const payload = {
            assigneeId: warehouse.id,
            destinationId: station.id,
            customerOrderId: customerOrder.id,
            orderParts: parts
        }

        onSelected(payload)
    }

    return (
        <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleOnClose}
                TransitionProps={{
                    onExited: handleOnExited
                }}>
            <DialogTitle>
                Create a new withdrawal order for {station && station.name}.
                <IconButton aria-label="close" style={{
                    position: "absolute",
                    top: 8, right: 8,
                }}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={6}/>
                    <Grid item xs={12} md={6}>
                        <Box pb={3}>
                            <Autocomplete
                                options={warehouses}
                                value={warehouse}
                                onChange={((event, value) => setWarehouse(value))}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) =>
                                    <TextField
                                        error={!!warehouseError}
                                        helperText={warehouseError}
                                        size={"small"} {...params} label="Warehouse"
                                        variant="outlined"/>}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}><Grid container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    spacing={1}>
                        <Grid item xs={4}><b>Part</b></Grid>
                        <Grid item xs={2} style={{textAlign: "center"}}><b>Unitary</b></Grid>
                        <Grid item xs={2} style={{textAlign: "right"}}><b>Total</b></Grid>
                    </Grid></Grid>
                    <Grid item xs={12} md={6}><Grid container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    spacing={1}>
                        <Grid item xs={4}><b>Part</b></Grid>
                        <Grid item xs={2} style={{textAlign: "center"}}><b>Unitary</b></Grid>
                        <Grid item xs={2} style={{textAlign: "right"}}><b>Total</b></Grid>
                    </Grid></Grid>
                    <Grid item xs={6}><Divider/></Grid>
                    <Grid item xs={6}><Divider/></Grid>
                    {suitableParts.map(part =>
                        <Grid item xs={12} md={6} key={part.code}>
                            <Grid container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  spacing={1}>
                                <Grid item xs={4}>
                                    {part.code}
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        value={part.quantity}
                                        onChange={(event => handleQtaChange(part.id, event.target.value))}
                                        type={"number"}
                                        InputProps={{inputProps: {min: 0}}}
                                        variant={"outlined"}
                                        margin="none"
                                        fullWidth
                                        size={"small"}/>
                                </Grid>
                                <Grid item xs={2} style={{textAlign: "right"}}>
                                    {part.quantity * customerOrder.quantity}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <Button fullWidth
                                onClick={handleOnSave}
                                type={"submit"}
                                variant={"contained"}
                                color={"primary"}>
                            {saving ? <CircularProgress size={24}/> : "Submit to station"}</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}