import React from 'react';
import {Switch} from "react-router-dom";
import MrpScreen from "./MrpScreen";
import FagitLayoutRoute from "../../../../layouts/fagit/FagitLayout";


export default function MrpRouter() {

    return (
        <Switch>
            <FagitLayoutRoute path="/role/ato/mrp" component={MrpScreen}/>
        </Switch>
    );
}
