import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {AppContext} from "../../../../App";
import {createCustomerOrder, fetchSessionOrders, fetchStations} from "../../../../utils/api";
import {Paper, Tab, Tabs, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CustomerOrderCreationDialog from "../../../../dialogs/customerOrder/CustomerOrderCreationDialog";
import {parse} from "qs";
import {useHistory} from "react-router-dom";
import CustomerOrderDetail from "./customerOrder/CustomerOrderDetail";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";


export default function MrpScreen({location}) {

    document.title = 'MRP';

    const [state, dispatch] = useContext(AppContext);

    const history = useHistory()

    const [customerOrders, setCustomerOrders] = useState([]);
    const [stations, setStations] = useState([]);
    const [loading, setLoading] = useState(true);

    const [customerOrderCreationDialogOpen, setCustomerOrderCreationDialogOpen] = useState(false);
    const [savingCustomerOrder, setSavingCustomerOrder] = useState(false);

    const customerOrderId = parse(location.search, {ignoreQueryPrefix: true}).co

    useEffect(() => {
        fetchSessionOrders({code: state.session.code})
            .then(result => {
                setCustomerOrders(result.payload)
                setLoading(false)
                if (result.payload.length > 0 && customerOrderId===undefined)
                    history.push({
                        search: `?co=${result.payload[0].id}`
                    })
            })
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))

        fetchStations()
            .then(result => {
                setStations(result.payload)
            })
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))


    }, [customerOrderId, history, state.session, dispatch])


    const handleNewCustomerOrder = () => {
        setCustomerOrderCreationDialogOpen(true)
    }

    const handleTabChange = (e, newValue) => {
        history.push({
            search: `?co=${newValue}`
        })
    }

    const selectedCustomerOrder = customerOrders.filter(o => o.id.toString() === customerOrderId)[0]


    const handleOnNewCustomerOrder = (customerOrder) => {
        setSavingCustomerOrder(true)
        customerOrder.sessionId = state.session.id
        createCustomerOrder({payload: customerOrder})
            .then((order) => {
                fetchSessionOrders({code: state.session.code})
                    .then(result => {
                        setCustomerOrders(result.payload)
                        history.push({
                            search: `?co=${order.payload.id}`
                        })
                    })
                    .catch(e => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                    }))
            })
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
            .finally(() => {
                setSavingCustomerOrder(false)
                setCustomerOrderCreationDialogOpen(false)
            })
    }

    return (
        <Grid container spacing={2} alignItems={"stretch"}>
            <Grid item xs={12} md={6}>
                <Typography variant={"h4"}>
                    Active customer orders
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} style={{textAlign: "right"}}>
                <Button
                    variant={"contained"}
                    size={"large"}
                    color="primary"
                    startIcon={<AddIcon/>}
                    onClick={handleNewCustomerOrder}>
                    Create new customer order
                </Button>

                <CustomerOrderCreationDialog
                    onSelected={handleOnNewCustomerOrder}
                    open={customerOrderCreationDialogOpen}
                    saving={savingCustomerOrder}
                    onClose={() => setCustomerOrderCreationDialogOpen(false)}/>

            </Grid>

            {!loading && (customerOrders.length > 0 ?
                <Grid item xs={12}>
                    <Paper square>
                        <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}
                            value={customerOrderId}
                        >
                            {customerOrders.map(customer_order =>
                                <Tab value={customer_order.id.toString()}
                                     icon={customer_order.completed ? <ThumbUpIcon/> : <HourglassEmptyIcon/>}
                                     label={`No. ${customer_order.id}`}
                                     key={customer_order.id}/>
                            )}

                        </Tabs>
                    </Paper>

                </Grid>
                :
                <Grid item xs={12} style={{textAlign: "center", paddingTop: 100}}>
                    <Typography variant={"h6"}>
                        Start by creating a new customer order using the button on the top right corner
                    </Typography>
                </Grid>
            )}

            {customerOrders.length > 0 && stations.length > 0 && customerOrderId && selectedCustomerOrder &&
            <Grid item xs={12}>
                <CustomerOrderDetail stations={stations}
                                     customerOrder={selectedCustomerOrder}/>
            </Grid>
            }
        </Grid>
    );
}
