import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {
    AppBar,
    Box,
    Checkbox,
    FormControlLabel,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Slide,
    Toolbar,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import OperationSlider from "../../../components/OperationSlider";
import ComponentsList from "../../../components/ComponentsList";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import ExtensionIcon from '@material-ui/icons/Extension';
import {fetchCustomerOrder, postOrderStart, postQualityCheckComplete} from "../../../utils/api";
import {AppContext} from "../../../App";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const extractCoQo = (orders) => {
    return orders.find(o => o.type === "QUALITY")
}

const extractCoOrders = (orders) => {
    return orders.filter(o => o.type !== "QUALITY")
}

export default function QualityOrderProcessingDialog({
                                                         customerOrderId,
                                                         station,
                                                         stationOrders,
                                                         open,
                                                         onClose,
                                                         onEnd
                                                     }) {


    const [, dispatch] = useContext(AppContext);
    const [operationIdx, setOperationIdx] = useState(0);
    const [results, setResults] = useState([]);

    const classes = useStyles();

    const [running, setRunning] = useState(false);

    const qualityOrder = extractCoQo(stationOrders)

    const operations = qualityOrder ? qualityOrder.operations : []
    const lastOperation = operations.length === operationIdx + 1

    const handleOnExited = () => {
        setOperationIdx(0)
        setRunning(false)
        setResults([])
    }

    const handleOnClose = (evt, reason) => {
        if (["backdropClick","escapeKeyDown"].includes(reason))
            return

        onClose()
    }

    const handleEnd = () => {
        const inQuality = results.filter(r => r.val).length
        const x = inQuality <= 1 ? "is" : "are"
        if (window.confirm(`You confirm that ${inQuality} over ${results.length} ${x} in quality?`))
            postQualityCheckComplete(
                {stationId: station.id, customerOrderId: customerOrderId, inQuality: inQuality})
                .then(onEnd)
                .catch(e => dispatch({
                    type: "SHOW_ALERT",
                    payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                }))
    }

    useEffect(() => {

        fetchCustomerOrder({id: customerOrderId})
            .then(result => {
                const tmp = []
                for (let i = 0; i < result.payload.quantity; i++)
                    tmp.push({idx: i, val: true})

                console.log(result)

                setResults(tmp)
            })

    }, [customerOrderId, open])

    const handleResultChange = (value) => (e, checked) => {
        const tmp = [...results]
        const changed = tmp.find(e => e.idx === value.idx)
        changed.val = checked
        setResults(tmp)
    }

    const handleInspectionStart = () =>{
        postOrderStart({orderId: qualityOrder.id})
            .then(() =>setRunning(true))
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
    }

    return (
        <Dialog fullScreen maxWidth={"md"} open={open} onClose={handleOnClose}
                TransitionComponent={Transition}
                TransitionProps={{
                    onExited: handleOnExited
                }}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton disabled={running} edge="start" color="inherit" onClick={handleOnClose}>
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Process quality order No. {qualityOrder && qualityOrder.customerOrderId}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} md={3} lg={2}>
                        <ComponentsList orders={extractCoOrders(stationOrders)}/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                        <Box m={4} style={{textAlign: "center"}}>
                            {!running &&
                            <Button variant={"contained"} color={"primary"} onClick={handleInspectionStart}>
                                Start the quality control
                            </Button>}

                            {running && <OperationSlider
                                value={operationIdx}
                                onChange={(v) => setOperationIdx(v)}
                                keyboardSlide={open}
                                operations={operations}/>}


                            {running && lastOperation &&
                            <Box m={3}>
                                <Button variant={"contained"}
                                        onClick={handleEnd}
                                        color={"primary"}>
                                    Confirm quality check finished
                                </Button>
                            </Box>}

                        </Box>
                    </Grid>

                    {running && <Grid item xs={12} md={3} lg={2}>
                        <List subheader={<ListSubheader disableSticky component="div">
                            Results
                        </ListSubheader>}>
                            {results.map(o =>
                                <ListItem key={o.idx}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ExtensionIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText>
                                        {o.idx + 1}
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <FormControlLabel
                                            control={<Checkbox
                                                onChange={handleResultChange(o)}
                                                checked={o.val} color="secondary"/>}
                                            label="In quality"
                                            labelPlacement="start"
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </List>
                    </Grid>}

                </Grid>
            </DialogContent>
        </Dialog>
    )
}