import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Box, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

export default function KanbanItem({kanban, onResume, disabledResume = false}) {

    const isWd = kanban.kanbanTag.type === "K_WITHDRAWAL"

    return (
        <Grid container>
            <Grid item xs={10}>
                <Typography variant={"subtitle1"}>
                    {isWd ? "Withdrawal" : "Production"} kanban info
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                Code
            </Grid>
            <Grid item xs={6}>
                {kanban.kanbanTag.code}
            </Grid>
            <Grid item xs={6}>
                Model code
            </Grid>
            <Grid item xs={6}>
                {kanban.kanbanTag.modelCode}
            </Grid>
            <Grid item xs={6}>
                Status
            </Grid>
            <Grid item xs={6}>
                {kanban.status}
            </Grid>
            <Grid item xs={6}>
                Assignee
            </Grid>
            <Grid item xs={6}>
                {kanban.assigneeName}
            </Grid>
            <Grid item xs={6}>
                Source
            </Grid>
            <Grid item xs={6}>
                {kanban.sourceName || "Board"}
            </Grid>
            <Grid item xs={6}>
                Destination
            </Grid>
            <Grid item xs={6}>
                {kanban.destinationName || "Board"}
            </Grid>
            {(kanban.status === "PROCESSING" || kanban.status === "WITHDRAWING") && !disabledResume &&
            <Grid item xs={12}>
                <Box pt={2}>
                    <Button fullWidth onClick={() => onResume(kanban)} variant={"contained"} color={"primary"}>
                        Resume
                    </Button>
                </Box>
            </Grid>
            }
        </Grid>
    )
}
