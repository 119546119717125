import React from 'react';
import {Switch} from "react-router-dom";
import FagitLayoutRoute from "../../layouts/fagit/FagitLayout";
import RoleScreen from "./RoleScreen";
import AtoRouter from "./ato/AtoRouter";
import {Router} from "@material-ui/icons";
import KanbanRouter from "./kanban/KanbanRouter";

export default function RoleRouter() {

    return (
        <Switch>
            <FagitLayoutRoute exact path="/role" component={RoleScreen}/>
            <Router path="/role/ato" component={AtoRouter}/>
            <Router path="/role/kanban" component={KanbanRouter}/>
        </Switch>
    );
}
