// eslint-disable-next-line no-unused-vars
import {API, SERVER} from "../constants";

const blobCache = {}

// methodo per generalizzare una richiesta https
const performCall = (path, headers, method, body, propertyCheck, ignoreResult = false) => {

    if (body !== null) {
        body = JSON.stringify(body)
    }

    // ritorno una promise
    return new Promise((resolve, reject) => {

        fetch(SERVER + path, {
            headers: headers,
            body: body,
            method: method
        })
            .then(response => {
                    // se ho ottenuto una risposta positiva allora controllo che ci sia quello che mi serve e lo ritorno
                    if (response.status < 400) {
                        // converto il risultato in oggetto js (json)
                        if (!ignoreResult)
                            response.json().then(json => {
                                if (!propertyCheck || json.hasOwnProperty(propertyCheck))
                                    resolve(json)
                                else
                                    reject({message: "Missing prop " + propertyCheck})
                            })
                        else resolve()
                        // altrimenti passo da qui e ritorno errore
                    } else {
                        response.json()
                            .then(json => {
                                console.log(json)
                                reject(json)
                            })
                            .catch(() => reject({message: "error unknown"}))
                    }
                }
            )
            .catch(() => reject({message: "Server unavailable"}))
    })
}

export const authenticate = (email, password) => {
    return performCall(
        API + `/administrator/authenticate`,
        {'Content-Type': 'application/json'},
        "POST",
        {email: email, password: password},
        "payload"
    )
}

export const fetchSessionDetail = ({code}) =>{

    return performCall(
        API + `/session/${code}`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchStations = () =>{

    return performCall(
        API + `/station/all`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchStation = ({id}) =>{

    return performCall(
        API + `/station/${id}`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchSessionKanban = ({sessionCode, tagCode}) =>{

    return performCall(
        API + `/tag/session-kanban?sessionCode=${sessionCode}&tagCode=${tagCode}`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchKanbanProcessable = ({sessionKanbanId, stationId}) =>{

    return performCall(
        API + `/tag/session-kanban/${sessionKanbanId}/processable?stationId=${stationId}`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchSessionStationOrders = ({stationId, sessionId}) =>{

    return performCall(
        API + `/station-order?sessionId=${sessionId}&stationId=${stationId}`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchStationOperations = ({stationId}) =>{

    return performCall(
        API + `/station/${stationId}/suitable-operations`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchSessions = ({apiKey}) =>{

    return performCall(
        API + `/session/all`,
        {
            'Authorization': 'Bearer ' + apiKey
        },
        "get",
        null,
        "payload"
    )
}

export const fetchStationParts = ({stationId}) =>{

    return performCall(
        API + `/station/${stationId}/suitable-parts`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchCustomerOrder = ({id}) =>{

    return performCall(
        API + `/customer-order/${id}`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchSessionOrders = ({code}) =>{

    return performCall(
        API + `/session/${code}/customer-orders`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchStationSessionKanbans = ({sessionCode, stationId}) =>{

    return performCall(
        API + `/station/${stationId}/kanban?sessionCode=${sessionCode}`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchCustomerOrderStationOrders = ({id}) =>{

    return performCall(
        API + `/customer-order/${id}/station-orders`,
        {},
        "get",
        null,
        "payload"
    )
}

export const fetchAdministratorSessionsPage = ({apiKey, page, size, sortDir, sortField}) =>{

    return performCall(
        API + `/session/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}`,
        {
            'Authorization': 'Bearer ' + apiKey
        },
        "get"
    )
}

export const fetchAdministratorsPage = ({apiKey, page, size, sortDir, sortField}) =>{

    return performCall(
        API + `/administrator/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}`,
        {
            'Authorization': 'Bearer ' + apiKey
        },
        "get"
    )
}

export const fetchTagsScreen = ({apiKey, page, size, sortDir, sortField}) =>{

    return performCall(
        API + `/tag/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}`,
        {
            'Authorization': 'Bearer ' + apiKey
        },
        "get"
    )
}

export const fetchSessionEvents = ({apiKey, page, size, sortDir, sortField, sessionCode}) =>{

    return performCall(
        API + `/session/${sessionCode}/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}`,
        {
            'Authorization': 'Bearer ' + apiKey
        },
        "get"
    )
}

export const createNewSession = ({apiKey, payload}) =>{

    return performCall(
        API + `/session`,
        {
            'Authorization': 'Bearer ' + apiKey,
            'Content-Type': 'application/json'
        },
        "put",
        payload
    )
}

export const createCustomerOrder = ({payload}) =>{

    return performCall(
        API + `/customer-order`,
        {
            'Content-Type': 'application/json'
        },
        "put",
        payload
    )
}

export const createProductionOrder = ({payload}) =>{

    return performCall(
        API + `/station-order/production`,
        {
            'Content-Type': 'application/json'
        },
        "put",
        payload
    )
}

export const createQualityOrder = ({payload}) =>{

    return performCall(
        API + `/station-order/quality`,
        {
            'Content-Type': 'application/json'
        },
        "put",
        payload
    )
}

export const createWithdrawalOrder = ({payload}) =>{

    return performCall(
        API + `/station-order/withdrawal`,
        {
            'Content-Type': 'application/json'
        },
        "put",
        payload
    )
}

export const postAssignTag = ({id, tag}) =>{

    return performCall(
        API + `/station-order/logistic/${id}/assign-tag?tagCode=${tag}`,
        {},
        "post"
    )
}

export const postDeliverOrder = ({id}) =>{

    return performCall(
        API + `/station-order/logistic/${id}/deliver`,
        {},
        "post"
    )
}

export const postProductionOrderComplete = ({stationId, customerOrderId}) =>{

    return performCall(
        API + `/station/${stationId}/complete-production-customer-order?customerOrderId=${customerOrderId}`,
        {},
        "post"
    )
}

export const postOrderStart = ({orderId}) =>{
    return performCall(
        API + `/station-order/${orderId}/start`,
        {},
        "post"
    )
}

export const postQualityCheckComplete = ({stationId, customerOrderId, inQuality}) =>{

    return performCall(
        API + `/station/${stationId}/quality-check-customer-order?customerOrderId=${customerOrderId}&inQuality=${inQuality}`,
        {},
        "post"
    )
}

export const postSessionKanbanUpdate = ({body}) =>{

    return performCall(
        API + `/tag/session-kanban`,
        {'Content-Type': 'application/json' },
        "post",
        body,
    )
}

export const postAssignSessionKanban = ({sessionKanbanId, stationId}) =>{

    return performCall(
        API + `/tag/session-kanban/${sessionKanbanId}/assign?stationId=${stationId}`,
        {'Content-Type': 'application/json'},
        "post",
    )
}

export const postProcessSessionKanban = ({sessionKanbanId, usedKanbans}) =>{

    return performCall(
        API + `/tag/session-kanban/${sessionKanbanId}/process`,
        {'Content-Type': 'application/json'},
        "post",
        usedKanbans
    )
}

export const postWithdrawSessionKanban = ({sessionKanbanId, stationId}) =>{

    return performCall(
        API + `/tag/session-kanban/${sessionKanbanId}/withdraw?stationId=${stationId}`,
        {'Content-Type': 'application/json'},
        "post",
    )
}

export const postProcessEndSessionKanban = ({sessionKanbanId, usedKanbans}) =>{

    return performCall(
        API + `/tag/session-kanban/${sessionKanbanId}/process-end`,
        {'Content-Type': 'application/json'},
        "post",
        usedKanbans
    )
}

export const postInspectionEndSessionKanban = ({sessionKanbanId, success}) =>{

    return performCall(
        API + `/tag/session-kanban/${sessionKanbanId}/inspection-end?success=${success}`,
        {'Content-Type': 'application/json'},
        "post"
    )
}

export const postBoardSessionKanban = ({sessionKanbanId}) =>{

    return performCall(
        API + `/tag/session-kanban/${sessionKanbanId}/on-board`,
        {'Content-Type': 'application/json'},
        "post",
    )
}

export const getBase64 = (apiKey, endpoint) => {

    // ritorno una promise
    return new Promise((resolve, reject) => {
        fetch(SERVER + endpoint, {
            headers: {
                'Authorization': 'Bearer ' + apiKey
            },
            method: 'GET'
        })
            .then(response => {

                    // se ho ottenuto una risposta positiva allora controllo che ci sia quello che mi serve e lo ritorno
                    if (response.status < 400)
                        // converto il risultato in testo
                        response.json().then(json => {
                            if (json.hasOwnProperty("data"))
                                resolve(json)
                            else
                                reject("Invalid image")
                        })
                    // altrimenti passo da qui e ritorno errore
                    else
                        reject(response.status)
                }
            )
    })
}

export const fetchBlob = (apiKey, subUrl, cache = true) => {

    // ritorno una promise
    return new Promise((resolve, reject) => {

        if (!!blobCache[subUrl] && cache) {
            console.log("loading blob from cache")
            resolve(blobCache[subUrl])
        } else {
            //console.log("loading blob from server")
            fetch(SERVER + subUrl, {
                headers: {
                    'Authorization': 'Bearer ' + apiKey
                },
                method: 'GET'
            })
                .then(response => {

                        // se ho ottenuto una risposta positiva allora controllo che ci sia quello che mi serve e lo ritorno
                        if (response.status < 400)
                            // converto il risultato in testo
                            response.blob().then(blob => {
                                blob = URL.createObjectURL(blob)
                                blobCache[subUrl] = blob
                                resolve(blob)
                            })
                        // altrimenti passo da qui e ritorno errore
                        else
                            reject(response.status)
                    }
                )
        }
    })
}



