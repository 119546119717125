import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../../App";
import {fetchSessionEvents, fetchSessions} from "../../../utils/api";
import Grid from "@material-ui/core/Grid";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import AdvancedTable from "../../../components/AdvancedTable";
import {useQueryParams} from "../../../hooks/useQueryParams";

const atoFields = [
    {key: "id", name: "ID"},
    {key: "tagCode", name: "Tag"},
    {key: "stationName", name: "Station", sort: "station_name"},
    {key: "stationOrderId", name: "SO id", sort: "stationOrder_Id"},
    {key: "stationOrderType", name: "SO type", sort: "stationOrder_Type"},
    {key: "customerOrderId", name: "CO id", sort: "stationOrder_CustomerOrder_Id"},
    {key: "type", name: "Evt. type"},
    {key: "timestampFormatted", name: "Event time", sort: "time"},
]

const kanbanFields = [
    {key: "id", name: "ID"},
    {key: "tagCode", name: "Tag"},
    {key: "tagType", name: "Tag type"},
    {key: "stationName", name: "Station", sort: "station_name"},
    {key: "type", name: "Evt. type"},
    {key: "timestampFormatted", name: "Event time", sort: "time"},
]

export default function EventsScreen({location}) {


    const [state, dispatch] = useContext(AppContext)

    const queryParams = useQueryParams()

    const sessionCode = queryParams.qp.sessionCode

    const [refreshTable, setRefreshTable] = useState(false);

    const [tableFields, setTableFields] = useState(atoFields);

    const [args, setArgs] = useState({apiKey: state.user.token});
    const [sessions, setSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);

    useEffect(() => {
        const selected = sessions.find(s => s.code === sessionCode)
        if (selected) {
            setSelectedSession(selected)
            if(selected.sessionType === "ASSEMBLY_TO_ORDER")
                setTableFields(atoFields)
            else
                setTableFields(kanbanFields)
            setArgs({apiKey: state.user.token, sessionCode: sessionCode})
        } else
            setSelectedSession(null)

    }, [sessionCode, sessions, state.user.token])

    useEffect(() => {
        fetchSessions({apiKey: state.user.token})
            .then(result => setSessions(result.payload))
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
    }, [dispatch, state.user])

    const handleSessionSelected = (evt, value) => {
        if (value)
            queryParams.setParams({sessionCode:value.code})
        else
            queryParams.popParam("sessionCode")
    }

    return (
        <Grid container justifyContent={"flex-end"}>
            <Grid item>
                <Autocomplete
                    options={sessions}
                    getOptionLabel={(option) => option.code}
                    value={selectedSession}
                    onChange={handleSessionSelected}
                    style={{width: 300}}
                    renderInput={(params) => <TextField {...params} fullWidth label="Session" variant="outlined"/>}
                />
            </Grid>
            {selectedSession && <Grid item xs={12}>
                <AdvancedTable fields={tableFields}
                               location={location}
                               requestArgs={args}
                               autoRefreshMs={2000}
                               mustRefresh={refreshTable}
                               onRefreshed={() => setRefreshTable(false)}
                               fetchingPageFunction={fetchSessionEvents}/>
            </Grid>}

        </Grid>
    );
}
