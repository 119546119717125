import React, {useContext} from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Switch from "@material-ui/core/Switch";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import {AppContext} from "../../App";


export default function UserEntry() {
    const [state, dispatch] = useContext(AppContext);

    const handleLogout = () => {
        // mando l'azione di logout
        dispatch({
            type: "LOGOUT"
        });
    }

    const handleToggleDark = (v, c) => {
        dispatch({
            type: "TOGGLE_THEME",
            payload: {darkTheme: c}
        })
    }

    return (
        <List style={{marginTop: "auto", cursor: "pointer"}}>

            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        {state.user.email.substr(0, 1).toUpperCase()}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText secondary={state.user.email}/>

            </ListItem>
            <Divider/>
            <ListItem>
                <ListItemIcon>
                    <Brightness4Icon/>
                </ListItemIcon>
                <ListItemText primary="Dark theme"/>
                <Switch
                    checked={!!state.darkTheme}
                    onChange={handleToggleDark}
                />
            </ListItem>
            <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                    <ExitToAppIcon/>
                </ListItemIcon>
                <ListItemText primary="Logout"/>
            </ListItem>
        </List>

    )
}