import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../App";
import Grid from "@material-ui/core/Grid";
import {fetchStations} from "../../utils/api";
import {Card, CardHeader, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";


export default function RoleScreen() {

    const [state, dispatch] = useContext(AppContext);
    const [stations, setStations] = useState([]);
    const history = useHistory()

    useEffect(() => {
        fetchStations()
            .then(result => {
                if (state.session.sessionType === "ASSEMBLY_TO_ORDER")
                    result.payload.unshift({
                        id: -1,
                        name: "MRP",
                        description: "Material Requirements Planning",
                        type: "MRP"
                    })

                setStations(result.payload)
            })
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
    }, [state.session, dispatch])

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile = (width <= 768);

    const handleRoleSelected = (station) => {

        const type = state.session.sessionType === "KANBAN" ? "kanban" : "ato"

        switch (station.type) {
            case "MRP":
                if (isMobile)
                    dispatch({
                        type: "SHOW_ALERT",
                        payload:
                            {
                                message: "When working with the MRP role, is suggested to use a laptop. If any question, please ask " + state.session.administratorName + ".",
                                autoHideDuration: null,
                                severity: "warning"
                            }
                    })
                history.push("/role/ato/mrp")
                break
            case "WAREHOUSE":
                history.push(`/role/${type}/warehouse?id=${station.id}`)
                break
            case "QUALITY":
                if(type === "ato")
                    history.push(`/role/${type}/station?id=${station.id}`)
                else
                    history.push(`/role/${type}/quality?id=${station.id}`)
                break
            default:
                history.push(`/role/${type}/station?id=${station.id}`)
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={"h4"}>
                    Select your role
                </Typography>
            </Grid>
            {stations.map(station =>
                <Grid key={station.id} item xs={12} lg={2}>
                    <Card style={{cursor: "pointer"}}
                          onClick={() => handleRoleSelected(station)}>
                        <CardHeader title={station.name} subheader={station.description}/>
                    </Card>
                </Grid>
            )}

        </Grid>
    );
}
