import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {authenticate} from "../../../utils/api";
import jwt_decode from 'jwt-decode';
import {AppContext} from "../../../App";
import checkToken from "../../../utils/authetnicationChecker";
import {Redirect} from "react-router-dom";
import {useQueryParams} from "../../../hooks/useQueryParams";


export default function LoginScreen() {

    const queryParams = useQueryParams()

    const userMail = queryParams.qp.new
    const expired = !!queryParams.qp.expired

    const [state, dispatch] = useContext(AppContext);
    const [email, setEmail] = useState(userMail ? userMail : window._env_.DEV === "true" ? "admin@supsi.ch" : "")
    const [password, setPassword] = useState(window._env_.DEV === "true" ? "admin" : "")
    const [loggingIn, setLoggingIn] = useState(false)
    const [loginError, setLoginError] = useState("")

    const [tokenExpired, setTokenExpired] = useState(false)

    useEffect(() => {
        if (expired)
            setTokenExpired(true)
    }, [expired])

    const handleLogin = () => {
        setLoggingIn(true)
        setLoginError("")

        authenticate(email, password)
            .then(result => {

                let decodedJwt = jwt_decode(result.payload)


                let userDetails = {
                    email: decodedJwt.sub,
                    token: result.payload,
                    expire: decodedJwt.exp
                }

                dispatch({
                    type: "LOGIN",
                    payload: userDetails
                })

            })
            .catch(error => {
                setLoginError(error.message)
                setLoggingIn(false)
            })
    }


    // se autenticato allora rimando alla home
    if (state.user && checkToken(state.user))
        return <Redirect to={"/admin/administrator"}/>

    return (
        <Grid
            style={{
                position: "absolute",
                top: "0",
                bottom: "0"
            }}
            container
            justify="center"
            alignItems="center"
            spacing={0}
        >
            <Grid item xs={4}>
                <Paper>
                    <Grid container
                          justify="center"
                          alignItems="center">
                        <Grid item xs={12}>

                            <Grid container
                                  spacing={3}
                                  style={{padding: 20}}
                                  justify="flex-end"
                            >
                                <Grid item xs={12}>
                                    <Typography>
                                        Administrative console login
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        type={"email"}
                                        label="Email"
                                        variant="outlined"
                                        value={email}
                                        onChange={(event => setEmail(event.target.value))}
                                        disabled={loggingIn}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        type={"password"}
                                        label="Password"
                                        variant="outlined"
                                        value={password}
                                        disabled={loggingIn}
                                        onChange={(event => setPassword(event.target.value))}
                                    />
                                </Grid>

                                <Grid item xs={10} style={{textAlign: "right"}}>
                                    <Button
                                        variant={"contained"}
                                        size={"large"}
                                        color="primary"
                                        onClick={handleLogin}
                                    >
                                        {loggingIn ? <CircularProgress size={24}/> : "Login"}
                                    </Button>
                                </Grid>

                                <Grid hidden={!loginError} item xs={8} style={{textAlign: "right"}}>
                                    <Typography variant="body1" color={"error"}>
                                        {loginError}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <Typography variant="body1" color={"primary"}>
                    SUPSI - FaDeLaDiGT
                </Typography>

                <Snackbar open={tokenExpired} autoHideDuration={6000}
                          anchorOrigin={{horizontal: "center", vertical: "top"}}
                          onClose={() => setTokenExpired(false)}>
                    <Alert onClose={() => setTokenExpired(false)} severity="warning">
                        Token expired, please login again
                    </Alert>
                </Snackbar>

            </Grid>
        </Grid>

    );
}
