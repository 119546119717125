import TableContainer from "@material-ui/core/TableContainer";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {AppContext} from "../App";
import {useQueryParams} from "../hooks/useQueryParams";

const skeletons = ['20%', '10%', '18%', '30%', '5%']

export default function AdvancedTable({
                                          mustRefresh,
                                          onRefreshed,
                                          defaultSort = "id",
                                          fields,
                                          fetchingPageFunction,
                                          requestArgs,
                                          autoRefreshMs,
                                          onSelected = () => {
                                          }
                                      }) {

    const queryParams = useQueryParams();

    const [, dispatch] = useContext(AppContext)

    const pageNr = queryParams.qp.page || 0
    const size = queryParams.qp.size || 10
    const dir = queryParams.qp.dir || "DESC"
    const sort = queryParams.qp.sort || defaultSort

    const [page, setPage] = useState({
        content: [],
        size: 10,
        totalElements: 0,
        totalPages: 0,
        number: 0,
        sort: {
            direction: "DESC",
            property: "id"
        },
        loaded: false
    });

    // const deps = [dispatch, fetchingPageFunction, requestArgs, pageNr, size, dir, sort]
    //
    // useWhatChanged(deps, 'dispatch, fetchingPageFunction, args, page, size, dir, sort');

    useEffect(() => {

        const args = {
            page: pageNr,
            size: size,
            sortDir: dir,
            sortField: sort,
            ...requestArgs
        }

        const fetch = () => fetchingPageFunction(args).then(r =>
            setPage(r.payload)
        )
            .catch((e) => dispatch({type: "SHOW_ALERT", payload: {message: e.message, severity: "error"}}))


        fetch()


        const interval = autoRefreshMs ? setInterval(() => {
            fetch()
        }, 5000) : null

        return () => autoRefreshMs && clearInterval(interval)
    }, [dispatch, fetchingPageFunction, requestArgs, autoRefreshMs, pageNr, size, dir, sort])


    const handleTableUpdate = useCallback((p, s, d, so) => {
        queryParams.setParams({
            page: p,
            size: s,
            sort: so,
            dir: d
        })
    }, [queryParams])

    useEffect(() => {
        if (mustRefresh) {
            handleTableUpdate(pageNr, size, dir, sort)
            onRefreshed();
        }

    }, [pageNr, size, dir, sort, handleTableUpdate, mustRefresh, onRefreshed])


    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {fields.map(field =>
                                <TableCell key={field.key}>
                                    {field.sorting !== false ? <TableSortLabel
                                            active={page.sort.property === field.key || page.sort.property === field.sort}
                                            direction={page.sort.ascending ? "asc" : "desc"}
                                            onClick={() => handleTableUpdate(pageNr, size, dir === "ASC" ? "DESC" : "ASC", field.sort ? field.sort : field.key)}
                                        >
                                            {field.name}
                                        </TableSortLabel>
                                        :
                                        field.name
                                    }
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {page.loaded === false ?
                            // se la propietà loaded è false allora mostro gli skeleton
                            skeletons.map(width => (
                                <TableRow key={width}>
                                    {fields.map(field =>
                                        <TableCell key={"fi_" + field.key}>
                                            <Skeleton style={{width: Math.floor(Math.random() * 90) + 10}}
                                                      variant="text"/>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))

                            :

                            // renderizzo le cose normali ricevute dal server
                            page.content.map(row => (
                                <TableRow hover key={row[defaultSort]} style={{cursor: "pointer"}}
                                          onClick={() => onSelected(row)}>
                                    {fields.map(field =>
                                        <TableCell
                                            style={{
                                                maxWidth: field.maxWidth,
                                                // textAlign: typeof row[field.key] == 'number'? "right" : "left"
                                            }}
                                            key={"fi_" + field.key}>
                                            {field.boolean ? row[field.key] ? "Yes" : "No" : row[field.key]}
                                        </TableCell>
                                    )}

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={page.totalElements}
                rowsPerPage={page.size}
                page={page.number}
                onPageChange={(event, newPageNr) => handleTableUpdate(newPageNr, size, dir, sort)}
                onRowsPerPageChange={(event) => handleTableUpdate(pageNr, parseInt(event.target.value), dir, sort)}
            />
        </div>)
}