import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function CustomerOrderCreationDialog({
                                                        open,
                                                        onClose,
                                                        onSelected,
                                                        saving
                                                    }) {


    const [modelCode, setModelCode] = useState("")
    const [quantity, setQuantity] = useState("1")

    const [modelCodeError, setModelCodeError] = useState(null)
    const [quantityError, setQuantityError] = useState(null)

    const handleSave = () => {
        let error = false
        if (modelCode == null || modelCode.length === 0) {
            error = true
            setModelCodeError("The model code should not be empty")
        } else
            setModelCodeError(null)

        if (quantity == null || quantity.length === 0) {
            error = true
            setQuantityError("The quantity should not be empty")
        } else
            setQuantityError(null)

        if (!error)
            onSelected({
                modelCode: modelCode,
                quantity: quantity
            })
    }

    const handleOnExited = () => {
        setModelCode("")
        setQuantity("1")
        setModelCodeError(null)
        setQuantityError(null)
    }

    return (
        <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose}
                TransitionProps={{
                    onExited: handleOnExited
                }}>
            <DialogTitle>
                Create a new customer order
                <IconButton aria-label="close" style={{
                    position: "absolute",
                    top: 8, right: 8,
                }}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent={"space-between"} spacing={3} style={{
                    margin: 0,
                    width: '100%',
                }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Model code"
                            variant="outlined"
                            value={modelCode}
                            disabled={saving}
                            error={modelCodeError}
                            helperText={modelCodeError}
                            onChange={(event => setModelCode(event.target.value))}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            type={"number"}
                            error={quantityError}
                            helperText={quantityError}
                            disabled={saving}
                            label="Quantity"
                            variant="outlined"
                            value={quantity}
                            onChange={(event => setQuantity(event.target.value))}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant={"contained"}
                            size={"large"}
                            disabled={saving}
                            fullWidth
                            style={{height: "100%"}}
                            color="primary"
                            onClick={handleSave}
                        >
                            {saving ? <CircularProgress size={24}/> : "save"}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}