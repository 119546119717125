import React, {useCallback, useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {AppContext} from "../../../App";
import {
    fetchSessionKanban,
    fetchStation,
    fetchStationSessionKanbans,
    postAssignSessionKanban,
    postBoardSessionKanban,
    postInspectionEndSessionKanban,
    postProcessEndSessionKanban,
    postProcessSessionKanban,
    postWithdrawSessionKanban
} from "../../../utils/api";
import KanbanDetailDialog, {
    ASSIGN,
    BOARD,
    DELIVERED,
    PROCESSED,
    PROCESSING,
    WITHDRAWING
} from "../../../dialogs/kanban/KanbanDetailDialog";
import WithdrawingDialog from "../../../dialogs/WithdrawingDialog";
import KanbanProcessingDialog from "../../../dialogs/kanban/KanbanProcessingDialog";
import KStationContent from "./KStationContent";
import KQualityContent from "./KQualityContent";
import {addBarcodeListener, removeBarcodeListener} from "../../../utils/AutomaticBarcodeScan";
import {useQueryParams} from "../../../hooks/useQueryParams";


export default function KStationContainer() {

    const [state, dispatch] = useContext(AppContext);

    const queryParams = useQueryParams()

    const stationId = queryParams.qp.id
    const [station, setStation] = useState();
    const [kanbans, setKanbans] = useState([]);

    const [productionKanbans, setProductionKanbans] = useState([]);
    const [modelsCount, setModelsCount] = useState([]);
    const [semiAssCount, setSemiAssCount] = useState([]);

    const [selectedKanban, setSelectedKanban] = useState();
    const [selectedKanbanOpen, setSelectedKanbanOpen] = useState(false);

    const [producingKanbanOpen, setProducingKanbanOpen] = useState(false);
    const [withdrawingKanbanOpen, setWithdrawingKanbanOpen] = useState(false);

    const handleOnWithdrawalDelivered = () => {

        postAssignSessionKanban({
            sessionKanbanId: selectedKanban.id,
            stationId: selectedKanban.kanbanTag.stationId
        })
            .then(() => dispatch({
                type: "SHOW_ALERT",
                payload: {message: "Kanban assigned to " + selectedKanban.kanbanTag.stationName}
            }))
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
            .finally(() => {
                fetchKanbans()
                setWithdrawingKanbanOpen(false)
            })
    }

    const handleOnProductionProcessed = (kanban) => {

        postProcessEndSessionKanban({sessionKanbanId: kanban.id, usedKanbans: []})
            .then(() => setProducingKanbanOpen(false))
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
            .finally(fetchKanbans)

    }


    const handleOnInspectionEnd = (kanban, success) => {

        postInspectionEndSessionKanban({sessionKanbanId: kanban.id, success: success})
            .then(() => setProducingKanbanOpen(false))
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
            .finally(fetchKanbans)

    }

    const fetchKanbans = useCallback(() => {
        fetchStationSessionKanbans({sessionCode: state.session.code, stationId: stationId})
            .then(r => setKanbans(r.payload))
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))

    }, [state.session, stationId, dispatch])

    useEffect(() => {

        fetchStation({id: stationId})
            .then(result => {
                setStation(result.payload)
            })
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))

        fetchKanbans()

        // occhio che i kanbans sono una dipendenza di uno use effect
        const interval = setInterval(() => {
            fetchKanbans()
        }, 5000);

        return () => clearInterval(interval)
    }, [state.session, dispatch, stationId, fetchKanbans])


    const onCodeScanned = useCallback((code) => {
        dispatch({
            type: "SHOW_ALERT",
            payload: {message: "QR code found, loading informations"}
        })

        fetchSessionKanban({sessionCode: state.session.code, tagCode: code})
            .then(result => {
                setSelectedKanban(result.payload)
                setSelectedKanbanOpen(true)
            })
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))

    }, [dispatch, state.session])

    useEffect(() => {
        if (!producingKanbanOpen && !withdrawingKanbanOpen)
            addBarcodeListener("StationContainer", onCodeScanned)

        return () =>
            removeBarcodeListener("StationContainer")
    }, [onCodeScanned, producingKanbanOpen, withdrawingKanbanOpen ])

    useEffect(() => {

        const productions = kanbans.filter(k => k.kanbanTag.type === "K_PRODUCTION" && k.kanbanTag.stationId === station.id)

        setProductionKanbans(productions)

        const models = productions.map(k => k.kanbanTag.modelCode)

        const semiOccurrencesMap = kanbans.filter(k => k.kanbanTag.type === "K_PRODUCTION" && k.kanbanTag.stationId !== station.id).reduce((acc, k) => {
                acc[k.kanbanTag.stationName] = (acc[k.kanbanTag.stationName] || 0) + 1
                return acc
            }
            , {});

        const partsOccurrences = kanbans.filter(k => k.kanbanTag.type === "K_WITHDRAWAL").reduce((acc, k) => {
                acc[k.kanbanTag.modelCode] = (acc[k.kanbanTag.modelCode] || 0) + 1
                return acc
            }
            , {});

        models.forEach(model => {
            if (!Object.keys(partsOccurrences).includes(model))
                partsOccurrences[model] = 0
        })

        const modelOccurrences = Object.keys(partsOccurrences).map(code => {
            return {code: code, count: partsOccurrences[code]}
        })

        const semiOccurrences = Object.keys(semiOccurrencesMap).map(stationName => {
            return {name: stationName, count: semiOccurrencesMap[stationName]}
        })


        setSemiAssCount(semiOccurrences)
        setModelsCount(modelOccurrences)
    }, [kanbans, station])

    document.title = station ? station.name : "Station";

    const handleOnClose = (reason) => {

        switch (reason) {
            case ASSIGN:
                postAssignSessionKanban({sessionKanbanId: selectedKanban.id, stationId: stationId})
                    .then(() => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: "Kanban assigned to " + station.name}
                    }))
                    .catch(e => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                    }))
                break;
            case DELIVERED:
                postAssignSessionKanban({sessionKanbanId: selectedKanban.id, stationId: selectedKanban.destinationId})
                    .then(() => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: "Kanban assigned to " + selectedKanban.destinationName}
                    }))
                    .catch(e => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                    }))
                break;
            case PROCESSING:
                postProcessSessionKanban({sessionKanbanId: selectedKanban.id, usedKanbans: []})
                    .then(() => setProducingKanbanOpen(true))
                    .catch(e => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                    }))

                break;
            case WITHDRAWING:
                postWithdrawSessionKanban({sessionKanbanId: selectedKanban.id, stationId: stationId})
                    .then(() => setWithdrawingKanbanOpen(true))
                    .catch(e => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                    }))
                break;
            case PROCESSED:
                postProcessEndSessionKanban({sessionKanbanId: selectedKanban.id})
                    .then(() => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: "Kanban processed " + station.name}
                    }))
                    .catch(e => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                    }))
                break;
            case BOARD:
                postBoardSessionKanban({sessionKanbanId: selectedKanban.id})
                    .then(() => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: "Kanban placed on board"}
                    }))
                    .catch(e => dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                    }))
                break;
            default:
        }

        setSelectedKanbanOpen(false)
        fetchKanbans()
    }

    const handleOnResume = (kanban) => {
        setSelectedKanban(kanban)
        if (kanban.kanbanTag.type === "K_PRODUCTION")
            setProducingKanbanOpen(true)
        else
            setWithdrawingKanbanOpen(true)
    }

    return (
        <Grid container spacing={2}>

            <Grid item xs={12} md={6}>
                <Typography variant={"h4"}>
                    {station && station.name}
                </Typography>
            </Grid>


            {station && station.type === "QUALITY" ?

                <KQualityContent
                    station={station}
                    kanbans={kanbans}
                    onResume={handleOnResume}
                />
                :
                <KStationContent
                    station={station}
                    semiAssCount={semiAssCount}
                    kanbans={kanbans}
                    modelsCount={modelsCount}
                    onResume={handleOnResume}
                    productionKanbans={productionKanbans}
                />
            }

            {selectedKanban && <KanbanDetailDialog
                station={station}
                open={selectedKanbanOpen}
                onClose={handleOnClose}
                kanban={selectedKanban}
            />}

            {selectedKanban && <WithdrawingDialog
                station={station}
                open={withdrawingKanbanOpen}
                onDelivered={handleOnWithdrawalDelivered}
                onClose={() => setWithdrawingKanbanOpen(false)}
                parts={selectedKanban.kanbanTag.parts}
                destinationName={selectedKanban.kanbanTag.stationName}
            />}

            {selectedKanban && <KanbanProcessingDialog
                station={station}
                quality={station.type === "QUALITY"}
                open={producingKanbanOpen}
                onProcessed={handleOnProductionProcessed}
                onFailed={(kanban) => handleOnInspectionEnd(kanban, false)}
                onSuccess={(kanban) => handleOnInspectionEnd(kanban, true)}
                onClose={() => setProducingKanbanOpen(false)}
                kanban={selectedKanban}
            />}
        </Grid>
    );
}
