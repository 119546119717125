import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {Typography} from "@material-ui/core";
import {fetchKanbanProcessable} from "../../utils/api";
import {Alert} from "@material-ui/lab";
import KanbanItem from "../../components/KanbanItem";

export const ASSIGN = "ASSING"
export const DELIVERED = "DELIVERED"
export const PROCESSING = "PROCESSING"
export const CLOSED = "CLOSED"
export const PROCESSED = "PROCESSED"
export const WITHDRAWING = "WITHDRAW"
export const BOARD = "BOARD"

export default function KanbanDetailDialog({
                                               open,
                                               onClose,
                                               kanban,
                                               station,
                                           }) {

    const warehouse = station.type === "WAREHOUSE"
    const isProduction = kanban.kanbanTag.type === "K_PRODUCTION"
    const isProductionAndWStation = isProduction && kanban.kanbanTag.stationId === station.id
    const isAssignedToStation = kanban.assigneeId ? kanban.assigneeId === station.id : kanban.kanbanTag.stationId === station.id

    const [processable, setProcessable] = useState()

    useEffect(() => {
        if (kanban && station &&
            kanban.kanbanTag.type === "K_PRODUCTION" && kanban.kanbanTag.stationId) {
            fetchKanbanProcessable({sessionKanbanId: kanban.id, stationId: station.id})
                .then(r => setProcessable(r.payload))
                .catch()
        }
    }, [station, kanban])

    const handleSimpleClose = () => {
        onClose(CLOSED)
    }

    return (
        <Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleSimpleClose}
                TransitionProps={{
                    onExited: handleSimpleClose
                }}>
            <DialogTitle>
                Selected kanban
                <IconButton aria-label="close" style={{
                    position: "absolute",
                    top: 8, right: 8,
                }}
                            onClick={handleSimpleClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xw={12}>
                        <KanbanItem
                            kanban={kanban}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"h6"}>
                            Actions
                        </Typography>
                    </Grid>

                    {kanban.status === "BOARD" && isProduction && <Grid item xs={12}>
                        <Button
                            onClick={() => onClose(ASSIGN)}
                            disabled={!isAssignedToStation || !isProduction || warehouse}
                            fullWidth
                            variant={"contained"} color={"primary"}>Assign to me</Button>
                    </Grid>}

                    {kanban.status === "BOARD" && !isProduction && <Grid item xs={12}>
                        <Button
                            onClick={() => onClose(WITHDRAWING)}
                            disabled={isProduction || !warehouse}
                            fullWidth
                            variant={"contained"} color={"primary"}>Start withdrawing</Button>
                    </Grid>}

                    {kanban.status === "PROCESSED" && isProduction && warehouse && kanban.destinationId &&
                    <Grid item xs={12} >
                        <Button
                            onClick={() => onClose(DELIVERED)}
                            disabled={!warehouse}
                            fullWidth
                            variant={"contained"} color={"primary"}>Delivered</Button>
                    </Grid>}

                    {((kanban.status === "PROCESSED" && (!kanban.destinationId || !kanban.assigneeId )) ||
                        (!isProductionAndWStation && (processable?!processable.isQuality:true) && kanban.status === "WAITING")) &&
                    <Grid item xs={12}>
                        <Button
                            onClick={() => onClose(BOARD)}
                            fullWidth
                            variant={"contained"} color={"primary"}>on kanban board</Button>
                    </Grid>}

                    {kanban.status === "WAITING" && isProductionAndWStation && processable && !processable.isQuality &&
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {processable && <Grid item xs={12}>
                                {!processable.hasWithdrawal && <Alert severity={"info"}>
                                    Missing parts from warehouse
                                </Alert>}
                                {!processable.hasPreprocessed && <Alert severity={"info"}>
                                    Missing parts from upstream stations
                                </Alert>}
                            </Grid>}
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => onClose(PROCESSING)}
                                    disabled={
                                        warehouse ||
                                        !processable ||
                                        !processable.hasWithdrawal ||
                                        !processable.hasPreprocessed ? true : !(isAssignedToStation)
                                    }
                                    fullWidth
                                    variant={"contained"} color={"primary"}>Start working</Button>
                            </Grid>
                        </Grid>
                    </Grid>}

                    {kanban.status === "WAITING" && isProduction && processable && processable.isQuality &&
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => onClose(PROCESSING)}
                                    disabled={
                                        warehouse ||
                                        !processable ||
                                        !processable.isQuality ? true : !isAssignedToStation
                                    }
                                    fullWidth
                                    variant={"contained"} color={"primary"}>Start inspecting</Button>
                            </Grid>
                        </Grid>
                    </Grid>}

                </Grid>
            </DialogContent>
        </Dialog>
    )
}