import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Box, Typography} from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Divider from "@material-ui/core/Divider";
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';

export default function OrderItem({order, detailed = false}) {

    const wd = order.type === "WITHDRAWAL"
    const trp = order.type === "TRANSPORT"
    const q4 = order.type === "QUALITY"

    const delivered = order.destinationId === order.assigneeId

    return (
        <Grid container>
            <Grid item xs={10}>
                <Typography variant={"subtitle1"}>
                    {wd ? "Withdrawal" : trp? "Transport" : q4? "Quality" : "Production"} order info
                </Typography>
            </Grid>
            <Grid item xs={2} style={{textAlign: "right"}}>
                <Box mr={1}>
                    {order.completed ? <AssignmentTurnedInIcon/> : delivered? <MarkunreadMailboxIcon/> : <HourglassEmptyIcon/>}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {detailed && <Grid item xs={6}>
                Customer order
            </Grid>}
            {detailed && <Grid item xs={6}>
                {order.customerOrderId}
            </Grid>}
            <Grid item xs={6}>
                Status
            </Grid>
            <Grid item xs={6}>
                {order.completed ? "Done" : delivered? "Delivered" : "Pending"}
            </Grid>
            <Grid item xs={6}>
                Assignee
            </Grid>
            <Grid item xs={6}>
                {order.assigneeName}
            </Grid>
            {(trp) && <Grid item xs={6}>
                Source
            </Grid>}
            {(trp) && <Grid item xs={6}>
                {order.sourceStationName}
            </Grid>}
            {(wd || trp) && <Grid item xs={6}>
                Destination
            </Grid>}
            {(wd || trp) && <Grid item xs={6}>
                {order.destinationName}
            </Grid>}
            {(wd || trp) && <Grid item xs={6}>
                Tag code
            </Grid>}
            {(wd || trp) && <Grid item xs={6}>
                {order.tagCode? order.tagCode : "not set"}
            </Grid>}
        </Grid>
    )
}
