import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {fetchStationOperations} from "../../../utils/api";
import {AppContext} from "../../../App";
import {Checkbox} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ProductionOrderCreationDialog({
                                                          station,
                                                          customerOrder,
                                                          open,
                                                          onClose,
                                                          onSelected,
                                                          saving
                                                      }) {

    const [dispatch] = useContext(AppContext);

    const [suitableOperations, setSuitableOperations] = useState([]);

    useEffect(() => {
        if (!station)
            return

        fetchStationOperations({stationId: station.id})
            .then(r => setSuitableOperations(r.payload))
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
    }, [dispatch, station])

    const handleOnClose = (evt, reason) => {
        if (reason === "backdropClick")
            return

        onClose()
    }

    const handleOnExited = () => {
        setSuitableOperations([])
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const ops = [...event.target.elements]
            .filter(e => e.name !== "" && e.checked === true)
            .map(op => {
                return {id: op.name}
            })

        const payload = {
            assigneeId: station.id,
            customerOrderId: customerOrder.id,
            operations: ops
        }

        onSelected(payload)

    }

    return (
        <Dialog maxWidth={"md"} open={open} onClose={handleOnClose}
                TransitionProps={{
                    onExited: handleOnExited
                }}>
            <DialogTitle>
                Create a new production order for {station && station.name}.
                <IconButton aria-label="close" style={{
                    position: "absolute",
                    top: 8, right: 8,
                }}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}><Grid container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={1}>
                            <Grid item xs={4}><b>Operation</b></Grid>
                            <Grid item xs={2} style={{textAlign: "center"}}><b>Required</b></Grid>
                        </Grid></Grid>
                        <Grid item xs={12} md={6}><Grid container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={1}>
                            <Grid item xs={4}><b>Operation</b></Grid>
                            <Grid item xs={2} style={{textAlign: "center"}}><b>Required</b></Grid>
                        </Grid></Grid>
                        <Grid item xs={12}><Divider/></Grid>
                        {suitableOperations.map(op =>
                            <Grid item xs={12} md={6} key={op.id}>
                                <Grid container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      spacing={1}>
                                    <Grid item xs={4}>
                                        {op.code}
                                    </Grid>
                                    <Grid item xs={2} style={{textAlign: "center"}}>
                                        <Checkbox name={op.id.toString()} color={"primary"} defaultValue={false}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>

                            <Button fullWidth
                                    type={"submit"}
                                    variant={"contained"}
                                    color={"primary"}>
                                {saving ? <CircularProgress size={24}/> : "Submit to station"}</Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    )
}