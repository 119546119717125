import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import BarcodeScannerComponent from "react-qr-barcode-scanner";

export default function ScanTagDialog({
                                          open,
                                          onClose,
                                          onSelected,
                                      }) {


    const [tagCode, setTagCode] = useState("");
    const [enableCamera, setEnableCamera] = useState(false);
    const [cameraMode, setCameraMode] = useState(false);

    const handleOnSave = () => {
        onSelected(tagCode)
    }

    const handleOnExited = () => {
        setTagCode("")
        setEnableCamera(false)
    }

    const handleQrScan = (data) => {
        if (data !== null && cameraMode) {
            setCameraMode(false)
            onSelected(data)
        }
    }

    return (
        <Dialog fullWidth maxWidth={"xs"} open={open} onClose={onClose}
                TransitionProps={{
                    onExited: handleOnExited
                }}>
            <DialogTitle>
                Scan a tag
                <IconButton aria-label="close" style={{
                    position: "absolute",
                    top: 8, right: 8,
                }}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <React.Fragment>
                    {!enableCamera && <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <TextField
                                value={tagCode}
                                autoFocus
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        handleOnSave()
                                        ev.preventDefault();
                                    }
                                }}
                                onChange={(event => setTagCode(event.target.value))}
                                type={"text"}
                                variant={"outlined"}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <Button style={{height: "100%"}}
                                    disabled={window.location.protocol !== 'https:' && !window.location.host.includes("localhost")}
                                    onClick={() => {
                                        setCameraMode(true)
                                        setEnableCamera(true)
                                    }} fullWidth
                                    variant={"contained"} color={"primary"}>
                                <CameraEnhanceIcon/>
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth
                                    style={{height: "100%"}}
                                    onClick={handleOnSave}
                                    type={"submit"}
                                    variant={"contained"}
                                    color={"primary"}>
                                confirm
                            </Button>
                        </Grid>
                    </Grid>}

                    {enableCamera &&
                    <BarcodeScannerComponent
                        onUpdate={(err, result) => {
                            if (result) handleQrScan(result.text);
                        }}
                    />
                        // <QrReader
                        //     delay={300}
                        //     onError={() => {
                        //     }}
                        //     onScan={handleQrScan}
                        //     style={{width: '100%'}}
                        // />
                    }
                </React.Fragment>
            </DialogContent>
        </Dialog>
    )
}