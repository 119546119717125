import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Box, Card, CardContent, CardHeader, Tooltip, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import OrderItem from "../../../../../components/OrderItem";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Divider from "@material-ui/core/Divider";


const UpStreamsOrders = ({stations}) => {

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant={"subtitle1"}>
                    Upstream material
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {stations.map(s =>
                <Grid item xs={6} lg={4} key={s.id + "n"}>
                    <Box m={1}>
                        <Grid container alignItems={"center"} spacing={1}>
                            <Grid item>
                                {s.name}:
                            </Grid>
                            <Grid item>
                                <Tooltip title={s.orderDelivered ? "Delivered" : "Waiting"}>
                                    {s.orderDelivered ? <MarkunreadMailboxIcon/> : <HourglassEmptyIcon/>}
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}

export default function StationDetail({
                                          station,
                                          stations,
                                          orders,
                                          onCreateProductionOrder,
                                          onCreateWithdrawalOrder,
                                          onCreateQualityOrder
                                      }) {

    const withdrawalOrder = orders.find(o => o.type === "WITHDRAWAL")
    const productionOrder = orders.find(o => o.type === "PRODUCTION")
    const qualityOrder = orders.find(o => o.type === "QUALITY")

    const isProductionStation = station.type !== "QUALITY"

    const upStreams =
        station.upstreamStationsId.length > 0 ? stations
                .filter(s => (station.upstreamStationsId || []).includes(s.id))
                .map(s => {
                    const order = orders.find(o => o.sourceStationId === s.id)
                    s.orderDelivered = !!order
                    return s
                })
            : null

    return (
        <Grid item xs={12} md={6} lg={4} key={station.id}>
            <Card variant={"outlined"} style={{height: "100%"}}>
                <CardHeader
                    title={station.name}
                    subheader={station.description}
                />
                <CardContent>
                    <Grid container spacing={2}>
                        {productionOrder &&
                        <Grid item xs={12}>
                            <OrderItem order={productionOrder}/>
                        </Grid>}

                        {withdrawalOrder &&
                        <Grid item xs={12}>
                            <OrderItem order={withdrawalOrder}/>
                        </Grid>}

                        {qualityOrder &&
                        <Grid item xs={12}>
                            <OrderItem order={qualityOrder}/>
                        </Grid>}

                        {upStreams && <Grid item xs={12}>
                            <UpStreamsOrders stations={upStreams} orders={orders}/>
                        </Grid>}

                        {!(productionOrder && withdrawalOrder) && isProductionStation && <Grid item xs={12} md={6}>
                            {!productionOrder && <Button variant={"outlined"}
                                                         onClick={() => onCreateProductionOrder(station)}
                                                         fullWidth color={"primary"}>
                                Submit production order
                            </Button>}
                        </Grid>}
                        {!(productionOrder && withdrawalOrder) && isProductionStation && <Grid item xs={12} md={6}>
                            {!withdrawalOrder && <Button variant={"outlined"}
                                                         onClick={() => onCreateWithdrawalOrder(station)}
                                                         fullWidth color={"primary"}>
                                Submit withdrawal order
                            </Button>}
                        </Grid>}

                        {!qualityOrder && !isProductionStation && <Grid item xs={12} md={6}>
                            <Button variant={"outlined"}
                                    onClick={() => onCreateQualityOrder(station)}
                                    fullWidth color={"primary"}>
                                Submit quality check order
                            </Button>
                        </Grid>}

                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}
