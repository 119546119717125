import React from 'react';
import {Box, Paper} from "@material-ui/core";
import {SERVER} from "../constants";

export default function PartIcon({part}) {

    return (
        <Paper style={{backgroundColor: "white"}}>
            <Box p={0.3} style={{width:"100%", height: "100%"}}>
                <img
                    style={{width: "100%"}}
                    src={SERVER + "/data/parts/" + part.pic + ".png"}
                    alt={part.id}/>
            </Box>
        </Paper>
    )
}
