import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";

export default function SessionCreationDialog({
                                                  open,
                                                  onClose,
                                                  onSelected
                                              }) {


    return (
        <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
            <DialogTitle>
                Create a new session
                <IconButton aria-label="close" style={{
                    position: "absolute",
                    top: 8, right: 8,
                }}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3} style={{
                    margin: 0,
                    width: '100%',
                }}>
                    <Grid item xs={6}>
                        <Button color={"primary"}
                                variant={"contained"}
                                fullWidth onClick={() => onSelected("ASSEMBLY_TO_ORDER")}>
                            Assembly to order
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button color={"secondary"}
                                variant={"contained"}
                                fullWidth onClick={() => onSelected("KANBAN")}>Kanban</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}