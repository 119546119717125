import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {AppContext} from "../../../../App";
import {Card, CardContent, Paper, Tab, Tabs, Typography} from "@material-ui/core";
import {fetchSessionStationOrders, fetchStation} from "../../../../utils/api";
import PanToolIcon from '@material-ui/icons/PanTool';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import {Alert} from "@material-ui/lab";
import OrderItem from "../../../../components/OrderItem";
import Button from "@material-ui/core/Button";
import ProductionOrderProcessingDialog
    from "../../../../dialogs/stationOrder/production/ProductionOrderProcessingDialog";
import QualityOrderProcessingDialog from "../../../../dialogs/stationOrder/quality/QualityOrderProcessingDialog";
import {useQueryParams} from "../../../../hooks/useQueryParams";

const extractCustomerOrders = (orders, station) => {
    if (station === undefined)
        return []

    const upstream = station.upstreamStationsId
    const customerOrdersIds = [...new Set(orders.map(o => o.customerOrderId))]

    return customerOrdersIds.map(id => {
        // controllo se è pronto per la produzione
        let ready = true
        const coOrders = orders.filter(o => o.customerOrderId === id)
        const coOrdersSources = orders.filter(o => o.sourceStationId !== undefined).map(o => o.sourceStationId)

        if (station.type !== "QUALITY" && !coOrders.find(o => o.type === "PRODUCTION"))
            ready = false
        else if (station.type !== "QUALITY" && !coOrders.find(o => o.type === "WITHDRAWAL"))
            ready = false
        else if (station.type === "QUALITY" && !coOrders.find(o => o.type === "QUALITY"))
            ready = false
        else if (!upstream.every((val) => coOrdersSources.includes(val)))
            ready = false

        return {id: id, ready: ready}
    })
}

const extractCoOrders = (orders, customerOrderId) => {
    return orders.filter(o => o.customerOrderId.toString() === customerOrderId)
}

export default function StationScreen() {

    const [state, dispatch] = useContext(AppContext);

    const queryParams = useQueryParams();

    const stationId = queryParams.qp.id

    const customerOrderId = queryParams.qp.co || null
    const [processCustomerId, setProcessCustomerId] = useState();

    const [orders, setOrders] = useState([]);
    const [customerOrders, setCustomerOrders] = useState([]);

    const [station, setStation] = useState();

    useEffect(() => {

        fetchStation({id: stationId})
            .then(result => {
                setStation(result.payload)
            })
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))

        const fetchOrders = () => {
            fetchSessionStationOrders({stationId: stationId, sessionId: state.session.id})
                .then(result => {
                    setOrders(result.payload)
                })
                .catch(e => dispatch({
                    type: "SHOW_ALERT",
                    payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                }))
        }

        fetchOrders()

        const interval = setInterval(() => {
            fetchOrders()
        }, 5000);

        return () => clearInterval(interval)
    }, [state.session, dispatch, stationId])

    useEffect(() => {
        if (station !== undefined)
            setCustomerOrders(extractCustomerOrders(orders, station))
    }, [station, orders])

    const handleTabChange = (e, newValue) => {
        queryParams.setParams({
            co:newValue
        })
    }

    document.title = station ? station.name : "Station";

    return (
        <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item xs={12} md={6}>
                <Typography variant={"h4"}>
                    {station && station.name} customer orders
                </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
                {customerOrders.find(o => o.ready && o.id.toString() === customerOrderId) &&
                <Button fullWidth variant={"contained"}
                        onClick={() => setProcessCustomerId(customerOrderId)}
                        color={"primary"}>
                    Start processing order
                </Button>
                }
            </Grid>

            {customerOrders.length > 0 ?
                <Grid item xs={12}>
                    <Paper square>
                        <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}
                            value={customerOrderId}
                        >
                            {customerOrders.map(customer_order =>
                                <Tab value={customer_order.id.toString()}
                                     icon={customer_order.ready ? <ThumbUpIcon/> : <PanToolIcon/>}
                                     label={`No. ${customer_order.id}`}
                                     key={customer_order.id}/>
                            )}

                        </Tabs>
                    </Paper>

                </Grid>
                :
                <Grid item xs={12} style={{textAlign: "center", paddingTop: 100}}>
                    <Typography variant={"h6"}>
                        Currently no customer orders are assigned to the station
                    </Typography>
                </Grid>
            }

            {customerOrderId && customerOrders.find(c => c.id.toString() === customerOrderId) && <Grid item xs={12}>
                <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={12}>
                        {customerOrders.find(o => o.ready && o.id.toString() === customerOrderId) ?
                            <Alert severity="success">This order is ready to be processed</Alert> :
                            <Alert severity="warning">
                                This order is not ready to be processed, somethings is still missing
                            </Alert>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {extractCoOrders(orders, customerOrderId).map(o =>
                                <Grid item xs={12} md={6} lg={3} key={o.id}>
                                    <Card variant={"outlined"}>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <OrderItem order={o} detailed={station == null}/>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            }

            {station && station.type === "QUALITY" ?
                <QualityOrderProcessingDialog
                    customerOrderId={customerOrderId}
                    onEnd={() => {
                        setProcessCustomerId(undefined)
                        queryParams.popParam("co")
                    }}
                    open={!!processCustomerId}
                    onClose={() => {
                        setProcessCustomerId(undefined)
                    }}
                    stationOrders={extractCoOrders(orders, customerOrderId)}
                    station={station}
                />
                :
                <ProductionOrderProcessingDialog
                    onEnd={() => {
                        setProcessCustomerId(undefined)
                        queryParams.popParam("co")
                    }}
                    open={!!processCustomerId}
                    onClose={() => {
                        setProcessCustomerId(undefined)
                    }}
                    stationOrders={extractCoOrders(orders, customerOrderId)}
                    station={station}
                />}


        </Grid>
    );
}
