import React, {useContext, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {AppBar, Box, Slide, Toolbar, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {postOrderStart, postProductionOrderComplete} from "../../../utils/api";
import {AppContext} from "../../../App";
import OperationSlider from "../../../components/OperationSlider";
import ComponentsList from "../../../components/ComponentsList";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const extractCoPo = (orders) => {
    return orders.find(o => o.type === "PRODUCTION")
}

const extractCoWo = (orders) => {
    return orders.find(o => o.type === "WITHDRAWAL")
}

const extractCoOrders = (orders) => {
    return orders.filter(o => o.type !== "PRODUCTION")
}

export default function ProductionOrderProcessingDialog({
                                                            station,
                                                            stationOrders,
                                                            open,
                                                            onClose,
                                                            onEnd,
                                                        }) {


    const [operationIdx, setOperationIdx] = useState(0);

    const classes = useStyles();

    const [, dispatch] = useContext(AppContext);

    const [running, setRunning] = useState(false);

    const productionOrder = extractCoPo(stationOrders)
    const withdrawalOrder = extractCoWo(stationOrders)
    const operations = productionOrder ? productionOrder.operations : []
    const lastOperation = operations.length === operationIdx + 1

    const handleOnExited = () => {
        setOperationIdx(0)
        setRunning(false)
    }

    const handleOnClose = (evt, reason) => {
            if (["backdropClick","escapeKeyDown"].includes(reason))
                return

        onClose()
    }

    const handleEnd = () => {
        const tag = extractCoWo(stationOrders).tagCode

        if (window.confirm(`Have you used the ${tag} tag?`)) {
            postProductionOrderComplete(
                {stationId: station.id, customerOrderId: productionOrder.customerOrderId})
                .then(onEnd)
                .catch(e => dispatch({
                    type: "SHOW_ALERT",
                    payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                }))
        } else {
            // false
        }
    }

    const handleProductionStart = () =>{
        postOrderStart({orderId: productionOrder.id})
            .then(() =>setRunning(true))
            .catch(e => dispatch({
                type: "SHOW_ALERT",
                payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
            }))
    }

    return (
        <Dialog fullScreen maxWidth={"md"} open={open} onClose={handleOnClose}
                TransitionComponent={Transition}
                TransitionProps={{
                    onExited: handleOnExited
                }}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton disabled={running} edge="start" color="inherit" onClick={handleOnClose}>
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Process customer order No. {productionOrder && productionOrder.customerOrderId}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <ComponentsList orders={extractCoOrders(stationOrders)}/>
                    </Grid>

                    <Grid item xs={12} md={8} lg={10}>
                        <Box m={4} style={{textAlign: "center"}}>
                            {!running &&
                            <Button variant={"contained"} color={"primary"} onClick={handleProductionStart}>
                                Start fulfilling the order
                            </Button>}

                            {running && <OperationSlider
                                value={operationIdx}
                                onChange={(v) => setOperationIdx(v)}
                                keyboardSlide = {open}
                                operations={operations}/>}

                            {withdrawalOrder && <Box m={3}>
                                At the end, use the
                                <Typography variant={"button"}>
                                    {" " + withdrawalOrder.tagCode + " "}
                                </Typography>
                                tag.
                            </Box>}

                            {running && lastOperation &&
                            <Box m={3}>
                                <Button variant={"contained"}
                                        onClick={handleEnd}
                                        color={"primary"}>
                                    Confirm assembly finished and ready to pick-up
                                </Button>
                            </Box>}


                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}