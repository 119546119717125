import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from "@material-ui/core/Grid";
import {AppBar, Box, Button, Checkbox, Slide, Toolbar, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import PartIcon from "../components/PartIcon";

const useStyles = makeStyles(() => ({
    appBar: {
        position: 'relative',
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function WithdrawingDialog({
                                                    open,
                                                    onClose,
                                                    onDelivered,
                                                    destinationName,
                                                    parts,
                                                    hideLocation = false
                                                }) {

    const handleOnClose = (reason) => {
        if (["backdropClick"].includes(reason))
            return
        onClose()
    }

    const handleOnExited = () => {

    }

    const classes = useStyles();

    return (
        <Dialog fullScreen maxWidth={"md"} open={open} onClose={handleOnClose}
                TransitionComponent={Transition}
                TransitionProps={{
                    onExited: handleOnExited
                }}>
            <AppBar
                className={classes.appBar}
            >
                <Toolbar>
                    <Typography variant="h6">
                        Withdrawing menu
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item>
                        <Typography variant={"h6"}>
                            Destination:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={"h6"}>
                            {destinationName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}/>
                    <Grid item xs={12} sm={10} md={6} lg={4}>
                        <Grid container alignItems="center">
                            <Grid item xs={10}>
                                <Grid container spacing={1}>
                                    <Grid item xs={2} style={{textAlign: "right"}}>

                                    </Grid>
                                    <Grid item xs={4} style={{textAlign: "left"}}>
                                        <Typography
                                            component="span"
                                            variant="button"
                                            color="textPrimary"
                                        >
                                            Code
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} style={{textAlign: "center"}}>
                                        <Typography
                                            component="span"
                                            variant="button"
                                            color="textPrimary"
                                        >
                                            qta
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{textAlign: "center"}}>
                                        {!hideLocation && <Typography
                                            component="span"
                                            variant="button"
                                            color="textPrimary"
                                        >
                                            Location
                                        </Typography>}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} style={{textAlign: "right"}}>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>

                            {parts.map((part) => (
                                <React.Fragment key={part.id}>
                                    <Grid item xs={10}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2} style={{textAlign: "center"}}>
                                                <Box m={0.2}>
                                                    <PartIcon part={part}/>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} style={{textAlign: "left"}}>
                                                <Typography
                                                    component="span"
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                >
                                                    {part.code}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{textAlign: "center"}}>
                                                <Typography
                                                    component="span"
                                                    variant="subtitle1"
                                                    color="textPrimary"
                                                >
                                                    {part.quantity}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} style={{textAlign: "center"}}>
                                                {!hideLocation &&
                                                <Typography
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="textPrimary"
                                                >
                                                    {part.location}
                                                </Typography>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2} style={{textAlign: "right"}}>
                                        <Checkbox defaultValue={false}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Button onClick={onDelivered} color={"primary"} fullWidth variant={"contained"}>
                            Withdrawal completed and delivered
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}