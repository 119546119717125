import React from 'react';
import {ListItem, ListItemAvatar, ListItemText, ListSubheader} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import InboxIcon from "@material-ui/icons/Inbox";
import List from "@material-ui/core/List";

export default function ComponentsList({orders}) {


    return (
        <List subheader={<ListSubheader disableSticky component="div">
            Required components
        </ListSubheader>}>
            {orders.map(o =>
                <ListItem key={o.id}>
                    <ListItemAvatar>
                        <Avatar>
                            <InboxIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={o.tagCode} secondary={o.type}/>
                </ListItem>
            )}
        </List>
    )
}
