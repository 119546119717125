import React, {useContext, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Card, CardContent, CardHeader, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {fetchSessionDetail} from "../utils/api";
import {useHistory} from "react-router-dom";
import {AppContext} from "../App";


export default function HomeScreen() {

    const [code, setCode] = useState(window._env_.DEV === "true" ? "lQXY" : "");
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [, dispatch] = useContext(AppContext);

    const handleSessionSelected = () => {
        setLoading(true)
        fetchSessionDetail({code: code})
            .then(result => {
                dispatch({
                    type: "SESSION_SELECTED",
                    payload: result.payload
                })
                dispatch({type: "SHOW_ALERT", payload: {message: "Session '" + code + "' selected"}})
                history.push("/role")
            })
            .catch(e => {
                setLoading(false)
                dispatch({
                    type: "SHOW_ALERT",
                    payload: {message: e.errors ? e.errors.message : e.message, severity: "error"}
                })
            })
    }

    return (
        <Grid
            style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0"
            }}
            container
            justifyContent="center"
            alignItems="center"
            spacing={0}
        >
            <Grid item xs={10} md={6} xl={3} lg={4}>
                <Card>
                    <CardHeader title={"Insert your session code"}/>
                    <CardContent>
                        <Grid container
                              justifyContent="center"
                              alignItems="center">
                            <Grid item xs={12}>
                                <Grid container
                                      spacing={3}

                                      justifyContent="center"
                                      alignItems="center">
                                    <Grid item xs={4}>Code</Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            value={code}
                                            disabled={loading}
                                            onKeyPress={(ev) => {
                                                console.log(`Pressed keyCode ${ev.key}`);
                                                if (ev.key === 'Enter') {
                                                    // Do code here
                                                    handleSessionSelected()
                                                    ev.preventDefault();
                                                }
                                            }}
                                            onChange={(event => setCode(event.target.value))}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth
                                                variant={"contained"}
                                                size={"large"}
                                                disabled={loading}
                                                color="primary"
                                                onClick={handleSessionSelected}
                                        >
                                            {loading && <CircularProgress size={24}/>}
                                            Start the experience!
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </Grid>
        </Grid>
    );
}
