import React from 'react';
import {Route, Switch} from "react-router-dom";
import AdminRouter from "./admin/AdminRouter";
import HomeScreen from "./HomeScreen";
import FagitLayoutRoute from "../layouts/fagit/FagitLayout";
import RoleRouter from "./role/RoleRouter";


export default function ScreenRouter() {
    return (
        <Switch>
            <Route path="/admin" component={AdminRouter}/>
            <Route path="/role" component={RoleRouter}/>
            <FagitLayoutRoute path="" component={HomeScreen}/>
        </Switch>


    );
}
