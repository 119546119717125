import React, {useContext} from 'react';
import {NavLink, Redirect, Route} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import UserEntry from "./UserEntry";

import PeopleIcon from '@material-ui/icons/People';

import './Style.css'
import PowerIcon from '@material-ui/icons/Power';

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import {Alert} from "@material-ui/lab";
import {AppContext} from "../../App";
import {AppBar, Avatar, Popover, Toolbar, Typography} from "@material-ui/core";
import checkToken from "../../utils/authetnicationChecker";
import {LocalOffer} from "@material-ui/icons";
import FlashOnIcon from '@material-ui/icons/FlashOn';

function AdminLayout({children}) {
    const [state, dispatch] = useContext(AppContext);

    // const userDetail = state.fagit_user

    // stato che identifica se aprire o meno il submenu di test
    const drawerWidth = 240;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const userMenuOpen = Boolean(anchorEl);

    const useStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            marginTop: 60
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.appBar
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            overflow: "hidden",
            marginTop: 60
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        toolbar: theme.mixins.toolbar,

        activeLink: {
            backgroundColor: "red"
        }
    }));

    const classes = useStyles();

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    if (!state.user || !checkToken(state.user))
        return <Redirect to={"/admin/login"}/>


    return (
        <div className={classes.root}>
            <CssBaseline/>


            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>

                    <Typography variant="h6">
                        Administrator console
                    </Typography>

                    <Avatar onClick={handleMenu} style={{marginLeft: "auto", cursor: "pointer"}}>
                        {"NB".substr(0, 1).toUpperCase()}
                    </Avatar>

                </Toolbar>

            </AppBar>


            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <List>

                    <ListItem button to="/admin/administrator" component={NavLink} activeClassName="Mui-selected">
                        <ListItemIcon><PeopleIcon/></ListItemIcon>
                        <ListItemText primary={"Administrators"}/>
                    </ListItem>

                    <ListItem button to="/admin/session" component={NavLink} activeClassName="Mui-selected">
                        <ListItemIcon><PowerIcon/></ListItemIcon>
                        <ListItemText primary={"Sessions"}/>
                    </ListItem>
                    <ListItem button to="/admin/event" component={NavLink} activeClassName="Mui-selected">
                        <ListItemIcon><FlashOnIcon/></ListItemIcon>
                        <ListItemText primary={"Events"}/>
                    </ListItem>

                    <ListItem button to="/admin/tag" component={NavLink} activeClassName="Mui-selected">
                        <ListItemIcon><LocalOffer/></ListItemIcon>
                        <ListItemText primary={"Tags"}/>
                    </ListItem>

                    <Divider/>

                </List>
            </Drawer>
            <main className={classes.content}>
                {children}
            </main>

            <Popover
                style={{zIndex: 1400 + 2} /*to compensate the height of the AppBar*/}
                open={userMenuOpen}
                anchorEl={anchorEl}
                data-html2canvas-ignore
                onClick={() => setAnchorEl(null)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <UserEntry userDetails={state.user}/>
            </Popover>

            <Snackbar open={!!state.alert && state.alert.display}
                      autoHideDuration={state.alert ? state.alert.autoHideDuration : 2000}
                      onClose={(e, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }

                          dispatch({
                              type: "HIDE_ALERT"
                          })
                      }}

                      anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                      }}>
                {state.alert && <Alert severity={state.alert ? state.alert.severity : ""}>
                    <Grid container>
                        <Grid item xs={state.alert.autoHideDuration === null ? 11 : 12}>
                            {state.alert ?
                                (Array.isArray(state.alert.message) ?
                                    <ul>
                                        {state.alert.message.map(txt => <li key={txt}>{txt}</li>)}
                                    </ul>
                                    : state.alert.message)
                                : ""}
                        </Grid>
                        {state.alert.autoHideDuration === null &&
                        <Grid item xs={1}>

                            <IconButton size="small"
                                        onClick={() => dispatch({
                                            type: "HIDE_ALERT"
                                        })}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>

                        </Grid>
                        }
                    </Grid>

                </Alert>}
            </Snackbar>


        </div>
    )
}

const AdminLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <AdminLayout>
                <Component {...matchProps} />
            </AdminLayout>
        )}/>
    )
};

export default AdminLayoutRoute;