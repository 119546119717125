import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import AdminsScreen from "./admins/AdminsScreen";
import LoginScreen from "./login/LoginScreen";
import AdminLayout from "../../layouts/administrator/AdminLayout";
import SessionsScreen from "./sessions/SessionsScreen";
import TagsScreen from "./tags/TagsScreen";
import EventsScreen from "./events/EventsScreen";


export default function AdminRouter() {

    return (
        <Switch>
            <Redirect exact from="/admin/" to="/admin/administrator"/>
            <AdminLayout path="/admin/administrator" component={AdminsScreen}/>
            <AdminLayout path="/admin/session" component={SessionsScreen}/>
            <AdminLayout path="/admin/tag" component={TagsScreen}/>
            <AdminLayout path="/admin/event" component={EventsScreen}/>
            <Route path="/admin/login" component={LoginScreen}/>
        </Switch>
    );
}
