import React from 'react';
import {Switch} from "react-router-dom";
import FagitLayoutRoute from "../../../layouts/fagit/FagitLayout";
import KStationContainer from "./KStationContainer";

export default function KanbanRouter() {

    return (
        <Switch>
            <FagitLayoutRoute exact path="/role/kanban/station" component={KStationContainer}/>
            <FagitLayoutRoute exact path="/role/kanban/warehouse" component={KStationContainer}/>
            <FagitLayoutRoute exact path="/role/kanban/quality" component={KStationContainer}/>
        </Switch>
    );
}
