export default function calculateTimeLeft(timestamp) {
    const difference = new Date(timestamp * 1000) - new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    } else return "0s"

    let result = ""

    if (timeLeft.days !== 0)
        result += timeLeft.days + "d "
    if (timeLeft.hours !== 0)
        result += timeLeft.hours + "h "
    if (timeLeft.minutes !== 0)
        result += timeLeft.minutes + "m "

    result += timeLeft.seconds + "s"


    return result;
};

export function compareInt(a, b) {
    if (a < b)
        return -1;
    if (a > b)
        return 1;

    return 0;
}